import {
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_DETAIL,
  GET_COUNTRIES_DETAIL_ERROR,
  GET_COUNTRY_PROPS,
  GET_COUNTRY_PROPS_ERROR,
  GET_CONTINENTS,
  GET_CONTINENTS_ERROR,
  GET_FORM_OF_GOVERMENT,
  GET_FORM_OF_GOVERMENT_ERROR,
  GET_REGIONS,
  GET_REGIONS_ERROR,
  GET_ORGANIZATION,
  GET_ORGANIZATION_ERROR,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_ERROR,
} from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const getCountries = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Map/map-countries", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_COUNTRIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNTRIES_ERROR,
      payload: error,
    });
  }
};

export const getCountryProps = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Country/admin/get-country-props", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_COUNTRY_PROPS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_COUNTRY_PROPS_ERROR,
      payload: error,
    });
  }
};

export const getCountryDetail = (data) => async (dispatch) => {
  try {
    const response = await axios.get(api + "Country/country-details/" + data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_COUNTRIES_DETAIL,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_COUNTRIES_DETAIL_ERROR,
      payload: error,
    });
  }
};

export const getContinents = () => async (dispatch) => {
  try {
    const res = await axios.post(
      api + "Country/get-continents",
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: GET_CONTINENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_CONTINENTS_ERROR,
      payload: error,
    });
  }
};

export const getFormOfGoverment = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Country/get-formofgoverment", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_FORM_OF_GOVERMENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_FORM_OF_GOVERMENT_ERROR,
      payload: error,
    });
  }
};

export const getRegions = () => async (dispatch) => {
  try {
    const res = await axios.post(
      api + "Country/get-regions",
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: GET_REGIONS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_REGIONS_ERROR,
      payload: error,
    });
  }
};

export const getOrganization = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Country/get-organization", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_ORGANIZATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_ORGANIZATION_ERROR,
      payload: error,
    });
  }
};

export const updateCountry = (data) => (dispatch) =>
  axios
    .put(`${api}Country/admin/update-country`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: UPDATE_COUNTRY,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: UPDATE_COUNTRY_ERROR,
        payload: error,
      });
    });
