import {
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_ERROR,
  POST_NEW_COMPANY,
  POST_NEW_COMPANY_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
} from "../types";

const initialState = {
  companies: [],
  companiesDetail: [],
  postCompany: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };

    case GET_COMPANY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_COMPANY_DETAIL:
      return {
        ...state,
        companiesDetail: action.payload,
        loading: false,
      };

    case GET_COMPANY_DETAIL_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_COMPANY:
      return {
        ...state,
        postCompany: action.payload,
        loading: false,
      };

    case POST_NEW_COMPANY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_COMPANY:
      return {
        ...state,
        loading: false,
      };

    case DELETE_COMPANY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case UPDATE_COMPANY:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_COMPANY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
