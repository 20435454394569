import React, { useState, useEffect } from "react";
import InputComponent from "../../../Components/Input";
import PhotoUpload from "../../../Components/PhotoUpload";
import SwitchComponent from "../../../Components/Switch";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import { postProductFile } from "../../../Store/actions/ProductAction";
import { AddMainAmmunitionCategory } from "../../../Store/actions/AmmunitionAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getMainAmmunitionCategory,
  updateMainAmmunitionCategory,
} from "../../../Store/actions/AmmunitionAction";

const EditMainAmmunition = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState([]);
  const [sendData, setSendData] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [checkedData, setCheckedData] = useState(false);
  const [dynamicSwitchData, setDynamicSwitchData] = useState([]);
  const [editData, setEditData] = useState({});
  const [images1, setImages1] = useState([]);

  const mainAmmunitionCategoryListData = useSelector(
    (state) => state.ammunitionList
  );
  const { loading, error, mainAmmunitionCategories } =
    mainAmmunitionCategoryListData;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let getQueryData = query.get("mainCatId");

        if (getQueryData != null) {
          if (
            !mainAmmunitionCategories ||
            mainAmmunitionCategories.length === 0
          ) {
            dispatch(getMainAmmunitionCategory());
          }

          let selectedCategory = mainAmmunitionCategories?.find(
            (x) => x.id == getQueryData
          );

          console.log(selectedCategory);

          setImages1(selectedCategory?.images1 || []);

          setEditData(selectedCategory);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [mainAmmunitionCategories, dispatch, query]);

  const handleClick = async () => {
    const formDataArray = [];
    const photoDataArray = [];

    for (let getFile of photo) {
      const formData = new FormData();
      const file = getFile;
      const fileName = getFile.name;

      formData.append("file", file);
      formData.append("fileName", fileName);
      formDataArray.push({ formData, fileName });
    }

    await Promise.all(
      formDataArray.map(({ formData }) => dispatch(postProductFile(formData)))
    );

    formDataArray.forEach(({ fileName }) => {
      photoDataArray.push(fileName);
    });

    let switchKeys = Object.keys(dynamicSwitchData);
    let switchValues = Object.values(dynamicSwitchData);

    let status = "";

    let statusCheck = switchKeys.includes("status");

    status = statusCheck && switchValues[switchKeys.indexOf("status")];

    setSendData((prevSendingData) => ({
      ...prevSendingData,
      id: editData.id,
      originalName: title,
      images1: images1.map((image) => ({
        url: image.url || "https://cdn.mildata.net/data/" + image.name,
      })),
      status: status,
    }));

    setSuccessModal(true);
  };

  const successModalButton = (e) => {
    if (e == "save") {
      setSuccessModal(false);
      console.log(sendData);
      dispatch(updateMainAmmunitionCategory(sendData));
      //window.location.reload(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  const getImageData = (data) => {
    if (data.target.files != null) {
      const fileList = data.target.files;
      const fileArray = Array.from(fileList);

      setPhoto(fileArray);

      setImages1((prevImages) => [...prevImages, ...fileArray]);
    }
  };

  const deleteFile = (e) => {
    const updatedImages = [...images1];
    updatedImages.splice(e, 1);
    setImages1(updatedImages);
  };

  const getDynamicSwitchData = (e) => {
    setDynamicSwitchData({
      ...dynamicSwitchData,
      ["status"]: e,
    });
  };

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Edit Main Ammunition Category
          </h2>
        </div>
        <div className="px-4 py-5 sm:p-6 ">
          <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Title
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"title"}
                    id={"title"}
                    value={title}
                    placeholder={"Title"}
                    setValue={(e) => setTitle(e.target.value)}
                    selectedValue={editData?.originalName}
                  ></InputComponent>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Status
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SwitchComponent
                    checked={editData?.status}
                    childToParent={(e) => {
                      setCheckedData(e);
                      getDynamicSwitchData(e);
                    }}
                  ></SwitchComponent>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Photo
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <PhotoUpload
                    isMultiple={true}
                    name={"photo"}
                    id={"photo"}
                    type={"file"}
                    value={(e) => getImageData(e)}
                  />

                  <div className="mt-2 flex justify-center">
                    {images1.length > 0 ? (
                      <div>
                        {images1.map((image, index) => (
                          <>
                            <img
                              key={index}
                              style={{
                                height: 200,
                                width: 200,
                                margin: 10,
                                marginBottom: 15,
                              }}
                              src={image.url || URL.createObjectURL(image)}
                            />
                            <button
                              onClick={() => deleteFile(index)}
                              type="button"
                              className="rounded float-right bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Delete
                            </button>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div>No photos uploaded</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleClick}
                className={`rounded-md bg-indigo-600
                 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500
                 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default EditMainAmmunition;
