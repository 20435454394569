import React, { useState, useEffect, useRef } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import InputComponent from "../../../Components/Input";
import SelectComponent from "../../../Components/Selectbox";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import TextArea from "../../../Components/Textarea";
import SwitchComponent from "../../../Components/Switch";
import WarningAlert from "../../../Components/WarningAlertModal";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import PhotoUpload from "../../../Components/PhotoUpload";
import AddableComponent from "../../../Components/AddableComponent";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getMainAmmunitionCategory,
  getSubAmmunitionCategory,
  getMunitionProps,
  getCountries,
  getCompanies,
  postNewMunition,
  getMunitions,
  updateAmmunition,
} from "../../../Store/actions/AmmunitionAction";
import { postProductFile } from "../../../Store/actions/ProductAction";

const EditAmmunition = () => {
  const dispatch = useDispatch();
  const [mainMunitionCategoryId, setMainMunitionCategoryId] = useState();
  const [subMunitionCategoryId, setSubMunitionCategoryId] = useState();
  const [switchStates, setSwitchStates] = useState({});
  const [warningModal, setWarningModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [dynamicSelectboxData, setdynamicSelectboxData] = useState([]);
  const [dynamicContentValues, setDynamicComponentsValues] = useState([]);
  const [dynamicSwitchData, setDynamicSwitchData] = useState([]);
  const [sendingData, setSendingData] = useState([]);
  const [ammunitionDetail, setAmmunitionDetail] = useState([]);
  const [newPhoto, setNewPhoto] = useState([]);
  const [photoLink, setPhotoLink] = useState([]);
  const [photo, setPhoto] = useState([]);

  // -------------------------------------------

  const munitionPropsListData = useSelector((state) => state.ammunitionList);
  const { loadingMunitionProp, errorMunitionProp, munitionProps } =
    munitionPropsListData;

  const mainAmmunitionCategoryListData = useSelector(
    (state) => state.ammunitionList
  );
  const { loadingMainMunition, errorMainMunition, mainAmmunitionCategories } =
    mainAmmunitionCategoryListData;

  const subAmmunitionCategoryListData = useSelector(
    (state) => state.ammunitionList
  );
  const {
    loadingsubAmmunitionCategories,
    errorsubAmmunitionCategories,
    subAmmunitionCategories,
  } = mainAmmunitionCategoryListData;

  const munitionsListData = useSelector((state) => state.ammunitionList);
  const { munitionsLoading, munitionError, munitions } = munitionsListData;

  const countryListData = useSelector((state) => state.ammunitionList);
  const { loading, error, countries } = countryListData;

  const companiesListData = useSelector((state) => state.ammunitionList);
  const { loadingCompanies, errorCompanies, companies } = companiesListData;

  // -------------------------------------------------

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    dispatch(getMainAmmunitionCategory());
    dispatch(getCountries());
    dispatch(getCompanies());

    setPhotoLink("");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let getQueryMainId = query.get("mainCatId");
        let getQuerySubId = query.get("subCatId");
        let getQueryAmmunitionId = query.get("ammunitionId");

        if (getQueryMainId != null) {
          setMainMunitionCategoryId(getQueryMainId);
          setSubMunitionCategoryId(getQuerySubId);
          dispatch(getSubAmmunitionCategory(getQueryMainId));
          dispatch(getMunitionProps(getQuerySubId));
          dispatch(getMunitions(getQuerySubId));

          let selectedAmmo = munitions?.find(
            (x) => x.id == getQueryAmmunitionId
          );

          setAmmunitionDetail(selectedAmmo);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [
    mainMunitionCategoryId,
    dispatch,
    query,
    mainAmmunitionCategories,
    subAmmunitionCategories,
  ]);

  useEffect(() => {
    if (ammunitionDetail) {
      setPhotoLink((prevData) =>
        ammunitionDetail?.images?.map((item) => item.url)
      );
    }
  }, [ammunitionDetail]);

  const successModalButton = (e) => {
    if (e == "save") {
      setSuccessModal(false);
      console.log(sendingData);
      dispatch(updateAmmunition(sendingData));
      //window.location.reload(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  const getDataFromCategorySelect = (data) => {
    dispatch(getSubAmmunitionCategory(data));
    setMainMunitionCategoryId(data);
  };

  const getDataFromSubCategorySelect = (data) => {
    dispatch(getMunitionProps(data));
    setSubMunitionCategoryId(data);
  };

  const getDataFromDynamicComponentSelect = (e, name, componentType) => {
    setdynamicSelectboxData({
      ...dynamicSelectboxData,
      [name]: componentType === "SingleSelect" ? e : e.map((x) => ({ id: x })),
    });
  };

  const getDynamicContentValue = (event, dbName, component) => {
    if (component == "Json") {
      setDynamicComponentsValues({
        ...dynamicContentValues,
        [dbName]: { ["value"]: event.target.value },
      });
    } else {
      setDynamicComponentsValues({
        ...dynamicContentValues,
        [dbName]: event.target.value,
      });
    }
  };

  const getDynamicSwitchData = (e, propName) => {
    setDynamicSwitchData({
      ...dynamicSwitchData,
      [propName]: e,
    });
  };

  const updateSwitchState = (key, value) => {
    setSwitchStates((prevStates) => ({ ...prevStates, [key]: value }));
  };

  const getImageData = async (data) => {
    if (data.target.files != null) {
      const fileList = data.target.files;
      const fileArray = Array.from(fileList);

      setPhoto((prevPhotos) => [...prevPhotos, ...fileArray]);

      setNewPhoto((prevPhotos) => [
        ...prevPhotos,
        ...fileArray.map((file) => URL.createObjectURL(file)),
      ]);
    }
  };

  const deleteFile = (e) => {
    const updatedImages = [...photoLink, ...newPhoto];
    updatedImages.splice(e, 1);
    setPhotoLink(updatedImages);
    setNewPhoto([]);
  };

  const warningModalButton = (e) => {
    setWarningModal(e);
  };

  const saveButton = async () => {
    let normalizeName = munitionProps.find(
      (res) => res.type === "productPropName"
    );

    let allData = {};
    let originalNameData = "";
    let originalTextData = "";
    let countriesData = [];
    let companiesData = [];
    let status = "";
    let isInMuseum = "";

    let inputKeys = Object.keys(dynamicContentValues);
    let inputValues = Object.values(dynamicContentValues);

    let selectKeys = Object.keys(dynamicSelectboxData);
    let selectValues = Object.values(dynamicSelectboxData);

    let switchKeys = Object.keys(dynamicSwitchData);
    let switchValues = Object.values(dynamicSwitchData);

    let isInMuseumCheck = switchKeys.includes("isInMuseum");
    let statusCheck = switchKeys.includes("status");

    isInMuseum =
      isInMuseumCheck && switchValues[switchKeys.indexOf("isInMuseum")];
    status = statusCheck && switchValues[switchKeys.indexOf("status")];

    const allDataKeys = [...inputKeys, ...selectKeys, ...switchKeys];

    let productPropKeys = Object.keys(
      ammunitionDetail[normalizeName.propName][0]
    );
    let productKeys = Object.keys(ammunitionDetail);

    const missingPropKeys = productPropKeys.filter(
      (property) => !allDataKeys.includes(property)
    );

    missingPropKeys.forEach((element) => {
      if (element !== "originalName" && element !== "originalText") {
        allData[element] = ammunitionDetail[normalizeName.propName][0][element];
      }
    });

    const missingProductKeys = productKeys.filter(
      (property) => !allDataKeys.includes(property)
    );

    missingProductKeys.forEach((element) => {
      if (element === "originalName") {
        originalNameData = ammunitionDetail[element];
      } else if (element === "originalTableName") {
        originalTextData = ammunitionDetail[element];
      } else if (element === "munitionCountries") {
        countriesData.push(
          ammunitionDetail[element].map((x) => ({ id: x.id }))
        );
      } else if (element === "munitionCompanies") {
        companiesData.push(
          ammunitionDetail[element].map((x) => ({ id: x.id }))
        );
      } else if (element === "status") {
        status = ammunitionDetail[element];
      } else if (element === "isInMuseum") {
        isInMuseumCheck = ammunitionDetail[element];
      }
    });

    inputKeys.forEach((element, index) => {
      if (element !== "originalName" && element !== "originalTableName") {
        allData[element] = inputValues[index];
      } else if (element === "originalName") {
        originalNameData = inputValues[index];
      } else if (element === "originalTableName") {
        originalTextData = inputValues[index];
      }
    });

    switchKeys.forEach((element, index) => {
      if (element !== "isInMuseum" && element !== "status") {
        allData[element] = switchValues[index];
      }
    });

    selectKeys.forEach((element, index) => {
      if (element !== "munitionCompanies" && element !== "munitionCountries") {
        allData[element] = selectValues[index];
      } else if (element === "munitionCountries") {
        countriesData.push(selectValues[index]);
      } else if (element === "munitionCompanies") {
        companiesData.push(selectValues[index]);
      }
    });

    const formDataArray = [];
    const photoDataArray = [];

    for (let getFile of photo) {
      const formData = new FormData();
      const file = getFile;
      const fileName = getFile.name;

      formData.append("file", file);
      formData.append("fileName", fileName);
      formDataArray.push({ formData, fileName });
    }

    formDataArray.forEach((x) => dispatch(postProductFile(x.formData)));

    formDataArray.forEach(({ fileName }) => {
      photoDataArray.push(fileName);
    });

    setSendingData((prevSendingData) => {
      return {
        ...prevSendingData,
        [normalizeName.propName]: [
          {
            ...allData,
          },
        ],
        id: ammunitionDetail["id"],
        originalName: originalNameData,
        originalTableName: originalTextData,
        munitionCategoryId: mainMunitionCategoryId,
        munitionSubCategoryId: subMunitionCategoryId,
        images: [
          ...photoLink.map((x) => ({
            url: x,
          })),
          ...photoDataArray.map((fileName) => ({
            url: "https://cdn.mildata.net/data/" + fileName,
          })),
        ],
        status: status,
        isInMuseum: isInMuseum,
        companies: companiesData[0],
        countries: countriesData[0],
      };
    });

    setSuccessModal(true);

    //}
  };

  return (
    <div className="divide-y divide-gray-300 rounded-lg bg-white shadow border border-gray-900/50">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Edit Munition
        </h2>
      </div>
      <div className="px-4 py-5 sm:p-6 ">
        <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Main Munition Category
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <SingleSelectComponent
                  options={mainAmmunitionCategories}
                  onChange={getDataFromCategorySelect}
                  selectedValue={
                    ammunitionDetail && ammunitionDetail?.munitionCategoryId
                  }
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Sub Munition Category
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <SingleSelectComponent
                  options={subAmmunitionCategories}
                  onChange={getDataFromSubCategorySelect}
                  selectedValue={
                    ammunitionDetail && ammunitionDetail?.munitionSubCategoryId
                  }
                />
              </div>
            </div>

            {munitionProps?.map((x) => {
              return x.type == "productPropName" ? (
                ""
              ) : (
                <div
                  key={x.id}
                  className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
                >
                  <label className="ml-10 block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                    {x.normalizedPropName}
                  </label>
                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                    {x.component == "Input" ? (
                      <InputComponent
                        key={x.id}
                        type={
                          x.type == "integer"
                            ? "number"
                            : x.type == "string"
                            ? "text"
                            : x.type == "float"
                            ? "number"
                            : "text"
                        }
                        name={x.normalizedPropName}
                        id={x.id}
                        placeholder={x.normalizedPropName}
                        setValue={(e) => getDynamicContentValue(e, x.propName)}
                        selectedValue={
                          x.propName == "originalName"
                            ? ammunitionDetail && ammunitionDetail[x.propName]
                            : x.propName == "originalTableName"
                            ? ammunitionDetail && ammunitionDetail[x.propName]
                            : ammunitionDetail &&
                              ammunitionDetail[
                                munitionProps?.find(
                                  (y) => y.type == "productPropName"
                                ).propName
                              ]?.[0][x.propName]
                        }
                      ></InputComponent>
                    ) : x.component == "Json" ? (
                      <InputComponent
                        key={x.id}
                        type={
                          x.type == "integer"
                            ? "number"
                            : x.type == "string"
                            ? "text"
                            : x.type == "float"
                            ? "float"
                            : "text"
                        }
                        name={x.normalizedPropName}
                        id={x.id}
                        placeholder={x.normalizedPropName}
                        setValue={(e) =>
                          getDynamicContentValue(e, x.propName, x.component)
                        }
                        // selectedValue={
                        //   ammunitionDetail[
                        //     munitionProps.find(
                        //       (y) => y.type == "productPropName"
                        //     ).propName
                        //   ]?.[0][x.propName].value
                        // }
                      ></InputComponent>
                    ) : x.component == "Textarea" ? (
                      <TextArea
                        key={x.id}
                        type={x.type}
                        name={x.normalizedPropName}
                        id={x.id}
                        placeholder={x.normalizedPropName}
                        setValue={(e) => getDynamicContentValue(e, x.propName)}
                      ></TextArea>
                    ) : x.component == "Select" ? (
                      <SelectComponent
                        options={
                          x.propName == "munitionCountries"
                            ? countries
                            : x.propName == "munitionCompanies"
                            ? companies
                            : []
                        }
                        selectedValues={
                          ammunitionDetail &&
                          (x.propName == "munitionCountries"
                            ? ammunitionDetail[x.propName]?.map(
                                (item) => item?.id
                              )
                            : x.propName == "munitionCompanies"
                            ? ammunitionDetail[x.propName]?.map(
                                (item) => item?.id
                              )
                            : ammunitionDetail[
                                munitionProps?.find(
                                  (y) => y.type === "productPropName"
                                ).propName
                              ]?.[0][x.propName]?.map((item) => item?.id))
                        }
                        onChange={(e) =>
                          getDataFromDynamicComponentSelect(
                            e,
                            x.propName,
                            x.component
                          )
                        }
                      />
                    ) : x.component == "SingleSelect" ? (
                      <SingleSelectComponent
                        options={[]}
                        onChange={(e) =>
                          getDataFromDynamicComponentSelect(
                            e,
                            x.propName,
                            x.component
                          )
                        }
                      />
                    ) : x.component == "Upload" ? (
                      <>
                        <PhotoUpload
                          isMultiple={true}
                          name={"photo"}
                          id={"photo"}
                          type={"file"}
                          value={(e) => getImageData(e)}
                        />

                        <div className="mt-2 grid grid-cols-2 gap-2 justify-center">
                          {photoLink ? (
                            [...photoLink, ...newPhoto].map((file, index) => (
                              <div
                                key={index}
                                className="flex flex-col items-center"
                              >
                                <img
                                  style={{
                                    height: 200,
                                    width: 200,
                                    margin: 10,
                                    marginBottom: 15,
                                  }}
                                  src={file}
                                />
                                <button
                                  onClick={() => deleteFile(index)}
                                  type="button"
                                  className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                  Delete
                                </button>
                              </div>
                            ))
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </>
                    ) : x.component == "Switch" ? (
                      <SwitchComponent
                        key={x.id}
                        switchLabel={x.propName}
                        checked={
                          ammunitionDetail &&
                          (ammunitionDetail[x.propName] ||
                            ammunitionDetail[
                              munitionProps?.find(
                                (y) => y.type == "productPropName"
                              )?.propName
                            ]?.[0][x.propName])
                        }
                        childToParent={(e) => {
                          getDynamicSwitchData(e, x.propName);
                          updateSwitchState(x.propName, e);
                        }}
                      ></SwitchComponent>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={saveButton}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      {warningModal == true && (
        <div>
          <WarningAlert
            visible={warningModal}
            childToParent={warningModalButton}
            text={"Please fill in the blank fields"}
            title={"Empty Data"}
          ></WarningAlert>
        </div>
      )}

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default EditAmmunition;
