import { LOGIN, LOGIN_ERROR } from "../types";
const initialState = {
  loading: true,

  token: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload,
        loading: false,
      };

    case LOGIN_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
