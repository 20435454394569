import { LOGIN, LOGIN_ERROR } from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const login = (data) => {
  return (dispatch) => {
    axios
      .post(api + "Auth/authenticate", data)
      .then((response) => {
        dispatch({
          type: LOGIN,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
};
