export default function InputComponent({
  inputLabel,
  type,
  name,
  id,
  placeholder,
  value,
  setValue,
  children,
  selectedValue,
}) {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {inputLabel}
      </label>
      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            defaultValue={selectedValue}
            //value={selectedValue}
            type={type}
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={setValue}
            required
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        {children}
      </div>
    </div>
  );
}
