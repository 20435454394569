import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function SelectBox({ options, selectedValue, onChange }) {
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (selectedValue) {
      setSelectedOption(
        options?.find(
          (option) =>
            option?.classId === selectedValue || option?.id === selectedValue
        )
      );
    }
  }, [selectedValue, options]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption?.classId || selectedOption?.id || "");
  };

  return (
    <div>
      <Select
        options={options}
        getOptionLabel={(option) =>
          option["originalName"] ||
          option["originalType"] ||
          option["continent"] ||
          ""
        }
        getOptionValue={(option) =>
          `${option["id"] || option["classId"] || ""}`
        }
        value={selectedOption}
        onChange={handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
}
