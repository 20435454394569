import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import ReactPaginate from "react-paginate";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import {
  getUpdates,
  getCategories,
  deleteUpdate,
} from "../../../Store/actions/UpdatesAction";

const TABLE_HEAD = ["Product", "Update Type", "Status", "isDeleted"];

const ListUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [selectedUpdateId, setSelectedUpdateId] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [noDataToDisplay, setNoDataToDisplay] = useState(false);

  const categoriesListData = useSelector((state) => state.updatesTypeList);
  const { loading, error, categories } = categoriesListData;

  const updateList = useSelector((state) => state.updatesTypeList);
  const { loadUpdates, errUpdates, updates } = updateList;

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
  };

  const deleteButton = (e) => {
    setSelectedUpdateId(e);
    setSuccessModal(true);
  };

  const successModalButton = (e) => {
    if (e == "save") {
      dispatch(deleteUpdate(selectedUpdateId));
      window.location.reload(false);
      setSuccessModal(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  const getDataFromCategorySelect = (data) => {
    dispatch(getUpdates(data));
    setCategoryId(data);
    let slicedData = updates.filter((res) => res.originalName != "");
    setCurrentItems(slicedData);

    console.log(slicedData)

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(slicedData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(slicedData.length / itemsPerPage));
    setNoDataToDisplay(true);
  };

  const handlePageClick = (event) => {
    if (updates && updates.length > 0) {
      const newOffset = (event.selected * itemsPerPage) % updates.length;
      setItemOffset(newOffset);

      // Update current items based on the new offset
      updateCurrentItems(newOffset);
    }
  };

  const updateCurrentItems = (offset) => {
    const endOffset = offset + itemsPerPage;
    // Ensure updates is defined and has data before slicing
    if (updates && updates.length > 0) {
      const slicedData = updates.slice(offset, endOffset);
      setCurrentItems(slicedData);
    }
  };

  useEffect(() => {
    // This assumes updates data might change and needs to reset pagination and displayed items accordingly
    // Adjust this as needed based on how your data loading logic works
    if (updates && updates.length > 0) {
      updateCurrentItems(itemOffset); // Update with the current offset, or reset to 0 as needed
      setPageCount(Math.ceil(updates.length / itemsPerPage)); // Ensure pageCount is updated based on the new data
    }
  }, [updates]); // Re-run this effect if 'updates' changes

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Updates
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            You can see Update List in this page
          </p>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <div className="mt-2 sm:col-span-1 sm:mt-0">
              <SingleSelectComponent
                options={categories}
                onChange={getDataFromCategorySelect}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="relative max-w-xs border dark:border-gray-700 rounded-md">
            <label className="sr-only">Search</label>
            <input
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              className="block w-full p-3 pl-10 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 dark:border-gray-700 dark:text-gray-400"
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchChange}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {noDataToDisplay == false ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr className="even:bg-gray-50">
                      <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        Please select a category from the select box above.
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {currentItems
                        .filter((data) => {
                          return data.simpleProductVM.originalName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase());
                        })
                        .map((x, index) => (
                          <tr key={index} className="even:bg-gray-50">
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {x.simpleProductVM.originalName}
                            </td>
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {x.updateTypeVM.originalType}
                            </td>
                            <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {x.status == true ? (
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  True
                                </span>
                              ) : (
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 text-red-700 py-1 text-xs font-medium ring-red-600/10 ring-1 ring-inset">
                                  False
                                </span>
                              )}
                            </td>
                            <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {x.simpleProductVM.isDeleted == true ? (
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  True
                                </span>
                              ) : (
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 text-red-700 py-1 text-xs font-medium ring-red-600/10 ring-1 ring-inset">
                                  False
                                </span>
                              )}
                            </td>

                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <Link
                                to={`/EditUpdate?id=${x.updateId}&categoryId=${categoryId}`}
                              >
                                <button
                                  type="button"
                                  className="rounded-full bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  Edit
                                </button>
                              </Link>
                              <button
                                onClick={() => deleteButton(x.updateId)}
                                type="button"
                                className="rounded-full bg-red-600 ml-3 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <ReactPaginate
                    breakLabel={<span className="mr-4">...</span>}
                    nextLabel={
                      <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md">
                        <ChevronRightIcon />
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel={
                      <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md mr-4">
                        <ChevronLeftIcon />
                      </span>
                    }
                    containerClassName="flex items-center justify-center mt-8 mb-4"
                    pageClassName="block border- border-solid border-lightGray hover:bg-lightGray w-10 h-10 flex items-center justify-center rounded-md mr-4"
                    activeClassName="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={"Delete"}
            text={"Are you sure?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default ListUpdate;
