import React, { useState, useEffect } from "react";
import InputComponent from "./Input";
import SingleSelectComponent from "./SingleSelectbox";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";

export default function AddableComponent({
  array,
  prop,
  childToParent,
  selectedValue,
  selectedProductId,
}) {
  const [addableComponentData, setAddableComponentData] = useState([
    {
      [prop]: {
        id: "",
        count: "",
      },
    },
  ]);
  const [selectedPropId, setSelectedPropId] = useState([]);
  const [test, setTest] = useState(false);

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue.length == 0) {
        setAddableComponentData([
          {
            [prop]: {
              id: "",
              count: "",
            },
          },
        ]);
      } else {
        setAddableComponentData(
          selectedValue.map((item) => ({
            [prop]: {
              id: item?.id,
              count: item?.count,
            },
          }))
        );
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue && selectedValue.length > 0) {
      setSelectedPropId(selectedValue.map((item) => item.id));
    }
  }, [selectedValue]);

  const sendData = (e) => {
    childToParent(e, test);
    setTest(false);
  };

  const addNewSelectedPropData = () => {
    setAddableComponentData((prevData) => [
      ...prevData,
      {
        [prop]: {},
      },
    ]);
    setTest(true);
  };

  const deleteSelectedPropData = (index) => {
    const newArray = [...addableComponentData];
    newArray.splice(index, 1);
    setAddableComponentData(newArray);
  };

  const getSelectedPropData = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...addableComponentData];

    onChangeValue[index][prop][name] = parseInt(value);
    onChangeValue[index][prop]["id"] = selectedPropId[index];

    setAddableComponentData(onChangeValue);
    sendData(addableComponentData);
  };

  const getDataFromSelect = (data) => {
    setSelectedPropId((prevSelectedCountryIds) => [
      ...prevSelectedCountryIds,
      data,
    ]);

    selectedProductId([...selectedPropId, data]);
  };

  return (
    <div>
      {addableComponentData?.map((item, index) => (
        <div className="mt-2 sm:col-span-1 sm:mt-0" key={index}>
          <SingleSelectComponent
            options={array}
            onChange={getDataFromSelect}
            selectedValue={selectedValue && item[prop]?.id}
          />
          <InputComponent
            type={"number"}
            name={"count"}
            id={"count"}
            placeholder={"Count"}
            selectedValue={selectedValue && item[prop]?.count}
            setValue={(event) => getSelectedPropData(event, index)}
          >
            {addableComponentData.length > 1 && (
              <button
                onClick={() => deleteSelectedPropData(index)}
                type="button"
                className="mb-7 ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <MinusCircleIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            )}

            {index == addableComponentData.length - 1 && (
              <button
                onClick={() => addNewSelectedPropData()}
                type="button"
                className="mb-7 ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusCircleIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            )}
          </InputComponent>
        </div>
      ))}
    </div>
  );
}
