import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import {
  getUserLog,
  getUserLogFiltered,
} from "../../../Store/actions/UserAction";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
} from "@material-tailwind/react";
import { DayPicker } from "react-day-picker";

const TABLE_HEAD = ["Username", "Name", "Surname", "Ip Address", "Login Date"];

const LogUser = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const userData = useSelector((state) => state.user);
  const { load, error, userLog } = userData;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userLog.length;
    setItemOffset(newOffset);
  };

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "yyyy-MM-dd");
  };

  const getFilteredData = () => {
    let params = "";

    if (startDate && endDate && name) {
      params = `?from=${format(startDate, "yyyy-MM-dd")}&to=${format(
        endDate,
        "yyyy-MM-dd"
      )}&name=${name}`;
    } else if (startDate && endDate) {
      params = `?from=${format(startDate, "yyyy-MM-dd")}&to=${format(
        endDate,
        "yyyy-MM-dd"
      )}`;
    } else if (startDate && name) {
      params = `?from=${format(startDate, "yyyy-MM-dd")}&name=${name}`;
    } else if (endDate && name) {
      params = `?to=${format(endDate, "yyyy-MM-dd")}&name=${name}`;
    } else if (startDate) {
      params = `?from=${format(startDate, "yyyy-MM-dd")}`;
    } else if (endDate) {
      params = `?to=${format(endDate, "yyyy-MM-dd")}`;
    } else if (name) {
      params = `?name=${name}`;
    }

    if (params) {
      dispatch(getUserLogFiltered(params)); // API'den veri almayı varsayıyorum
    } else {
      dispatch(getUserLog());
    }

    // Filtreleme işlemi sonrası güncellemeler
    const filteredData = userLog.filter((res) => res.originalName != "");
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredData.length / itemsPerPage));
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Users
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            You can see User List in this page
          </p>
        </div>
        <div className="z-10">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="Select a Start Date"
                onChange={() => null}
                value={startDate ? format(startDate, "yyyy-MM-dd") : ""}
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                fromYear={2015}
                toYear={2025}
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                showOutsideDays
                classNames={{
                  caption:
                    "flex justify-center py-2 mb-4 relative items-center",
                  caption_label: "text-sm font-medium text-gray-900",
                  nav: "flex items-center",
                  nav_button:
                    "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                  nav_button_previous: "absolute left-1.5",
                  nav_button_next: "absolute right-1.5",
                  table: "w-full border-collapse",
                  head_row: "flex font-medium text-gray-900",
                  head_cell: "m-0.5 w-9 font-normal text-sm",
                  row: "flex w-full mt-2",
                  cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                  day: "h-9 w-9 p-0 font-normal",
                  day_range_end: "day-range-end",
                  day_selected:
                    "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                  day_today: "rounded-md bg-gray-200 text-gray-900",
                  day_outside:
                    "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                  day_disabled: "text-gray-500 opacity-50",
                  day_hidden: "invisible",
                }}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="z-10 ml-3">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="Select a End Date"
                onChange={() => null}
                value={endDate ? format(endDate, "yyyy-MM-dd") : ""}
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                fromYear={2015}
                toYear={2025}
                mode="single"
                selected={endDate}
                onSelect={setEndDate}
                showOutsideDays
                classNames={{
                  caption:
                    "flex justify-center py-2 mb-4 relative items-center",
                  caption_label: "text-sm font-medium text-gray-900",
                  nav: "flex items-center",
                  nav_button:
                    "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                  nav_button_previous: "absolute left-1.5",
                  nav_button_next: "absolute right-1.5",
                  table: "w-full border-collapse",
                  head_row: "flex font-medium text-gray-900",
                  head_cell: "m-0.5 w-9 font-normal text-sm",
                  row: "flex w-full mt-2",
                  cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                  day: "h-9 w-9 p-0 font-normal",
                  day_range_end: "day-range-end",
                  day_selected:
                    "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                  day_today: "rounded-md bg-gray-200 text-gray-900",
                  day_outside:
                    "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                  day_disabled: "text-gray-500 opacity-50",
                  day_hidden: "invisible",
                }}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="ml-3 sm:mt-0 sm:flex-none">
          <div className="relative max-w-xs border dark:border-gray-700 rounded-md">
            <Input label="Name" onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
        <div className="ml-3">
          <Button className="bg-indigo-600" size="md" onClick={getFilteredData}>
            Get
          </Button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentItems.map((x, index) => (
                    <tr key={index} className="even:bg-gray-50">
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {x.user.username}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {x.user.firstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {x.user.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {x.ipAddress}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatDate(x.loginDate)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                breakLabel={<span className="mr-4">...</span>}
                nextLabel={
                  <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md">
                    <ChevronRightIcon />
                  </span>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel={
                  <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md mr-4">
                    <ChevronLeftIcon />
                  </span>
                }
                containerClassName="flex items-center justify-center mt-8 mb-4"
                pageClassName="block border- border-solid border-lightGray hover:bg-lightGray w-10 h-10 flex items-center justify-center rounded-md mr-4"
                activeClassName="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogUser;
