import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getSubCategories,
  deleteSubCategory,
} from "../../../Store/actions/CategoriesAction";

const TABLE_HEAD = ["Sub Category", "Category"];

const ListSubCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [noDataToDisplay, setNoDataToDisplay] = useState(false);

  const categoriesListData = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoriesListData;

  const subCategoriesListData = useSelector((state) => state.subCategoryList);
  const { load, err, subCategoriesByCategory } = subCategoriesListData;

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
  };

  const getDataFromCategorySelect = (data) => {
    dispatch(getSubCategories(data));
    setCategoryId(data);
    setNoDataToDisplay(true);
  };

  const deleteButton = (e) => {
    setSubCategoryId(e);
    setSuccessModal(true);
  };

  const successModalButton = (e) => {
    if (e == "save") {
      dispatch(deleteSubCategory(subCategoryId));
      window.location.reload(false);
      setSuccessModal(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Sub Categories
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Please select the category to see the subcategories of the category
            you selected.
          </p>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <div className="mt-2 sm:col-span-1 sm:mt-0">
              <SingleSelectComponent
                options={categories}
                onChange={getDataFromCategorySelect}
              />
            </div>
          </div>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="relative max-w-xs border dark:border-gray-700 rounded-md">
            <label className="sr-only">Search</label>
            <input
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              className="block w-full p-3 pl-10 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 dark:border-gray-700 dark:text-gray-400"
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchChange}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {noDataToDisplay == false ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr className="even:bg-gray-50">
                      <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        Please select a category from the select box above.
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {TABLE_HEAD.map((head) => (
                        <th
                          key={head}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {head}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {subCategoriesByCategory
                      .filter((data) => {
                        return data.originalName
                          .toLowerCase()
                          .includes(searchValue.toLowerCase());
                      })
                      .map((x, index) => (
                        <tr key={index} className="even:bg-gray-50">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {x.originalName}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {
                              categories.find((f) => f.id === categoryId)
                                .originalName
                            }
                          </td>

                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link
                              to={`/EditSubCategory?id=${x.id}&categoryId=${categoryId}`}
                            >
                              <button
                                type="button"
                                className="rounded-full bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Edit
                              </button>
                            </Link>

                            <button
                              onClick={() => deleteButton(x.id)}
                              type="button"
                              className="rounded-full bg-red-600 ml-3 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={"Delete"}
            text={"Are you sure?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default ListSubCategories;
