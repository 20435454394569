import Embed from "@editorjs/embed";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  list: List,
  linkTool: LinkTool,
  header: Header,
  quote: Quote,
  checklist: CheckList,
  delimiter: Delimiter,
};
