import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../../Components/Input";
import PhotoUpload from "../../../Components/PhotoUpload";
import FileUpload from "../../../Components/FileUpload";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import SwitchComponent from "../../../Components/Switch";
import { getCountries } from "../../../Store/actions/CountryAction";
import { postProductFile } from "../../../Store/actions/ProductAction";
import {
  addReport,
  getReportTypes,
  getReports,
  updateReport,
} from "../../../Store/actions/ReportsAction";

const EditReport = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState([]);
  const [fileContent, setFileContent] = useState([]);
  const [countrySelectData, setCountrySelectData] = useState("");
  const [reportTypeSelectData, setReportTypeSelectData] = useState("");
  const [sendData, setSendData] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [checkedData, setCheckedData] = useState(false);
  const [dynamicSwitchData, setDynamicSwitchData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [editData, setEditData] = useState({});
  const [updateButtonVisib, setUpdateButtonVisib] = useState(false);
  const [updatedImageUrl, setUpdatedImageUrl] = useState([]);

  const countriesListData = useSelector((state) => state.countriesList);
  const { loading, error, countries } = countriesListData;

  const reportTypesListData = useSelector((state) => state.reportTypesList);
  const { reportTypesLoad, reportTypesError, reportTypes } =
    reportTypesListData;

  const reportListData = useSelector((state) => state.reportsList);
  const { loadingReports, errorReports, reports } = reportListData;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getReportTypes());
    dispatch(getReports());

    let getQueryData = query.get("id");

    if (getQueryData) {
      setUpdateButtonVisib(true);
      let selectedReport = reports.find((x) => x.id == getQueryData);


      setUpdatedImageUrl(selectedReport.imageUrl || []);

      setEditData(selectedReport);

      setCheckedData(selectedReport.status);
      setDynamicSwitchData({
        ...dynamicSwitchData,
        ["status"]: selectedReport.status,
      });
      setIsFormValid(true);
      validateUpdateForm();
    } else {
      validateForm();
    }
  }, [
    title,
    photo,
    fileContent,
    countrySelectData,
    reportTypeSelectData,
    editData,
  ]);

  const handleClick = async (but) => {
    if (but == "save") {
      validateForm();

      if (isFormValid) {
        const formDataArray = [];
        const photoDataArray = [];

        for (let getFile of photo) {
          const formData = new FormData();
          const file = getFile;
          const fileName = getFile.name;

          formData.append("file", file);
          formData.append("fileName", fileName);
          formDataArray.push({ formData, fileName });
        }

        await Promise.all(
          formDataArray.map(({ formData }) =>
            dispatch(postProductFile(formData))
          )
        );

        formDataArray.forEach(({ fileName }) => {
          photoDataArray.push(fileName);
        });

        const formDataArrayFile = [];
        const FileDataArray = [];

        for (let getFile of fileContent) {
          const formData = new FormData();
          const file = getFile;
          const fileName = getFile.name;

          formData.append("file", file);
          formData.append("fileName", fileName);
          formDataArrayFile.push({ formData, fileName });
        }

        await Promise.all(
          formDataArrayFile.map(({ formData }) =>
            dispatch(postProductFile(formData))
          )
        );

        formDataArrayFile.forEach(({ fileName }) => {
          FileDataArray.push(fileName);
        });

        let switchKeys = Object.keys(dynamicSwitchData);
        let switchValues = Object.values(dynamicSwitchData);

        let status = "";

        let statusCheck = switchKeys.includes("status");

        status = statusCheck && switchValues[switchKeys.indexOf("status")];

        setSendData((prevSendingData) => ({
          ...prevSendingData,
          originalName: title,
          url: "https://cdn.mildata.net/data/" + FileDataArray,
          countryId: countrySelectData,
          reportTypeId: reportTypeSelectData,

          imageUrl: "https://cdn.mildata.net/data/" + photoDataArray,
          status: status,
        }));

        setSuccessModal(true);
      }
    } else if (but == "update") {
      validateUpdateForm();
      const formDataArray = [];
      const photoDataArray = [];

      for (let getFile of photo) {
        const formData = new FormData();
        const file = getFile;
        const fileName = getFile.name;

        formData.append("file", file);
        formData.append("fileName", fileName);
        formDataArray.push({ formData, fileName });
      }

      await Promise.all(
        formDataArray.map(({ formData }) => dispatch(postProductFile(formData)))
      );

      formDataArray.forEach(({ fileName }) => {
        photoDataArray.push(fileName);
      });

      const formDataArrayFile = [];
      const FileDataArray = [];

      for (let getFile of fileContent) {
        const formData = new FormData();
        const file = getFile;
        const fileName = getFile.name;

        formData.append("file", file);
        formData.append("fileName", fileName);
        formDataArrayFile.push({ formData, fileName });
      }

      await Promise.all(
        formDataArrayFile.map(({ formData }) =>
          dispatch(postProductFile(formData))
        )
      );

      formDataArrayFile.forEach(({ fileName }) => {
        FileDataArray.push(fileName);
      });

      let switchKeys = Object.keys(dynamicSwitchData);
      let switchValues = Object.values(dynamicSwitchData);

      let status = "";

      let statusCheck = switchKeys.includes("status");

      status = statusCheck && switchValues[switchKeys.indexOf("status")];

      
      setSendData((prevSendingData) => ({
        ...prevSendingData,
        originalName: title == "" ? editData.originalName : title,
        url:
          fileContent == []
            ? editData.url
            : "https://cdn.mildata.net/data/" + FileDataArray,
        countryId:
          countrySelectData == "" ? editData.countryId : countrySelectData,
        reportTypeId:
          reportTypeSelectData == ""
            ? editData.reportTypeId
            : reportTypeSelectData,
        imageUrl:
          photo == []
            ? editData.imageUrl
            : "https://cdn.mildata.net/data/" + photoDataArray,
        status: status,
      }));

      setSuccessModal(true);
    }
  };

  const getDataFromCountrySelect = (data) => {
    setCountrySelectData(data);
  };

  const getDataFromReportTypeSelect = (data) => {
    setReportTypeSelectData(data);
  };

  const getImageData = (data) => {
    const fileList = data.target.files;
    const fileArray = Array.from(fileList);

    setPhoto(fileArray);
  };

  const getFileData = (data) => {
    const fileList = data.target.files;
    const fileArray = Array.from(fileList);

    setFileContent(fileArray);
  };

  const deletePhoto = (e) => {
    setPhoto((oldState) => oldState.filter((item) => item.id !== e));
  };

  const deleteFile = (e) => {
    const s = fileContent.filter((item, index) => index !== e);
    setFileContent(s);
  };

  const successModalButton = (e) => {
    if (updateButtonVisib == true) {
      setSuccessModal(false);
      //console.log(sendData);
      //dispatch(updateReport(sendData));
      //window.location.reload(false);
    } else {
      if (e == "save") {
        setSuccessModal(false);
        //console.log(sendData);
        //dispatch(addReport(sendData));
        //window.location.reload(false);
      } else if (e == "cancel") {
        setSuccessModal(false);
      }
    }
  };

  const getDynamicSwitchData = (e) => {
    setDynamicSwitchData({
      ...dynamicSwitchData,
      ["status"]: e,
    });
  };

  // const test = () => {
  //   console.log(countries?.find((x) => x.id === editData.countryId));
  // };

  const validateForm = () => {
    const isTitleValid = title.trim() !== "";
    const isCountryValid = countrySelectData !== "";
    const isReportTypeValid = reportTypeSelectData !== "";
    const isPhotoValid = photo.length > 0;
    const isFileValid = fileContent.length > 0;

    setIsFormValid(
      isTitleValid &&
        isCountryValid &&
        isReportTypeValid &&
        isPhotoValid &&
        isFileValid
    );
  };

  const validateUpdateForm = () => {
    const isPhotoValid = photo.length > 0;
    const isFileValid = fileContent.length > 0;

    setIsFormValid(isPhotoValid && isFileValid);
  };

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Edit Report
          </h2>
        </div>
        <div className="px-4 py-5 sm:p-6 ">
          <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Title
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"originalName"}
                    id={"originalName"}
                    placeholder={"Title"}
                    setValue={(e) => setTitle(e.target.value)}
                    selectedValue={editData && editData.originalName}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Country
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SingleSelectComponent
                    inputId={"country"}
                    name={"country"}
                    options={countries}
                    onChange={getDataFromCountrySelect}
                    selectedValue={editData && editData.countryId}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Report Type
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SingleSelectComponent
                    inputId={"reportTypes"}
                    name={"reportTypes"}
                    options={reportTypes}
                    onChange={getDataFromReportTypeSelect}
                    selectedValue={editData && editData.reportTypeId}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Status
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SwitchComponent
                    name={"status"}
                    id={"status"}
                    switchLabel={"status"}
                    checked={checkedData == true ? true : false} // Assuming checkedData is an object
                    childToParent={(e) => {
                      setCheckedData((prevData) => ({
                        ...prevData,
                        ["status"]: e,
                      }));
                      getDynamicSwitchData(e, "status");
                    }}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Content
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <FileUpload
                    name={"content"}
                    id={"content"}
                    type={"file"}
                    value={(e) => getFileData(e)}
                  />
                  <div className="mt-2 flex justify-center">
                    {fileContent ? (
                      <div>
                        {fileContent.map((file, index) => (
                          <>
                            <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                              {file.name}
                            </label>
                            <button
                              onClick={() => deleteFile(index)}
                              type="button"
                              className="rounded float-right bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Delete
                            </button>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Photo
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <PhotoUpload
                    isMultiple={true}
                    name={"photo"}
                    id={"photo"}
                    type={"file"}
                    value={(e) => getImageData(e)}
                  />
                  <div className="mt-2 flex justify-center">
                    {Array.isArray(updatedImageUrl) &&
                    updatedImageUrl.length > 0 ? (
                      <div>
                        {updatedImageUrl.map((image, index) => (
                          <div key={index}>
                            <img
                              style={{
                                height: 200,
                                width: 200,
                                margin: 10,
                                marginBottom: 15,
                              }}
                              src={image.url}
                              alt={`Image ${index + 1}`}
                            />
                            <button
                              onClick={() => deleteFile(index)}
                              type="button"
                              className="rounded float-right bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>No photos uploaded</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                onClick={test}
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                disabled={!isFormValid}
                type="button"
                onClick={() =>
                  handleClick(updateButtonVisib === true ? "update" : "save")
                }
                className={`rounded-md ${
                  isFormValid ? "bg-indigo-600" : "bg-gray-300"
                } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:${
                  isFormValid ? "bg-indigo-500" : "bg-gray-300"
                } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                {updateButtonVisib === true ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default EditReport;
