import {
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_SUB_CATEGORIES_BY_CATEGORIES,
  GET_SUB_CATEGORIES_BY_CATEGORIES_ERROR,
  GET_SUB_CATEGORY_PROPS,
  GET_SUB_CATEGORY_PROPS_ERROR,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_ERROR,
  GET_SUB_CATEGORY_SELECTBOX_DATA,
  GET_SUB_CATEGORY_SELECTBOX_DATA_ERROR,
  GET_PRODUCT,
  GET_PRODUCT_ERROR,
  POST_NEW_CATEGORIES,
  POST_NEW_CATEGORIES_ERROR,
  POST_NEW_SUB_CATEGORIES,
  POST_NEW_SUB_CATEGORIES_ERROR,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_ERROR,
  DELETE_SUB_CATEGORIES,
  DELETE_SUB_CATEGORIES_ERROR,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_ERROR,
  UPDATE_SUB_CATEGORIES,
  UPDATE_SUB_CATEGORIES_ERROR,
  GET_SUB_CATEGORY_DETAIL,
  GET_SUB_CATEGORY_DETAIL_ERROR,
} from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const getCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Product/categories", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CATEGORIES_ERROR,
      payload: error,
    });
  }
};

export const getSubCategories = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      api + "Product/sub-categories/" + data,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: GET_SUB_CATEGORIES_BY_CATEGORIES,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_SUB_CATEGORIES_BY_CATEGORIES_ERROR,
      payload: error,
    });
  }
};

export const getSubCategoryProps = (data) => {
  return (dispatch) => {
    axios
      .post(
        api + "Product/subcategory-props/" + data,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_SUB_CATEGORY_PROPS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_SUB_CATEGORY_PROPS_ERROR,
          payload: error,
        });
      });
  };
};

export const getSubCategorySelectBoxData = (data) => {
  return (dispatch) => {
    axios
      .get(api + "Product/admin/product-values/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_SUB_CATEGORY_SELECTBOX_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_SUB_CATEGORY_SELECTBOX_DATA_ERROR,
          payload: error,
        });
      });
  };
};

export const getProductDetails = (subCat, prodId) => {
  return (dispatch) => {
    axios
      .post(
        api + "Product/admin/product-detail",
        {
          subCategoryId: subCat,
          productId: prodId,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_DETAIL,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_PRODUCT_DETAIL_ERROR,
          payload: error,
        });
      });
  };
};

export const getProducts = (data) => (dispatch) =>
  axios
    .post(
      `${api}Product/sub-product/${data}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((response) => {
      dispatch({
        type: GET_PRODUCT,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: GET_PRODUCT_ERROR,
        payload: error,
      });
    });

export const AddMainCategory = (data) => (dispatch) =>
  axios
    .post(`${api}Product/admin/add-main-category`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: POST_NEW_CATEGORIES,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: POST_NEW_CATEGORIES_ERROR,
        payload: error,
      });
    });

export const AddSubCategory = (data) => (dispatch) =>
  axios
    .post(`${api}Product/admin/add-sub-category`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch({
        type: POST_NEW_SUB_CATEGORIES,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: POST_NEW_SUB_CATEGORIES_ERROR,
        payload: error,
      });
    });

export const deleteMainCategory = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Product/admin/remove-main-category/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_CATEGORIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CATEGORIES_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteSubCategory = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Product/admin/remove-sub-category/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_SUB_CATEGORIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SUB_CATEGORIES_ERROR,
          payload: error,
        });
      });
  };
};

export const updateCategory = (data) => {
  return (dispatch) => {
    axios
      .put(api + "Product/admin/update-product-main-category", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: UPDATE_CATEGORIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_CATEGORIES_ERROR,
          payload: error,
        });
      });
  };
};

export const updateSubCategory = (data) => {
  return (dispatch) => {
    axios
      .put(api + "Product/admin/update-product-sub-category", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: UPDATE_SUB_CATEGORIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_SUB_CATEGORIES_ERROR,
          payload: error,
        });
      });
  };
};

export const getSubCategoryDetail = (data) => (dispatch) =>
  axios
    .get(`${api}Product/sub-category-detail/${data}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch({
        type: GET_SUB_CATEGORY_DETAIL,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: GET_SUB_CATEGORY_DETAIL_ERROR,
        payload: error,
      });
    });
