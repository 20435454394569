import { DocumentIcon } from "@heroicons/react/24/solid";
export default function FileUpload({ inputLabel, id, name, type, value }) {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {inputLabel}
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="text-center">
          <DocumentIcon
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label className="relative  rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
              <span>Upload a file</span>
              <input
                id={id}
                name={name}
                type={type}
                className="sr-only"
                onChange={value}
                multiple
              />
            </label>
            <p className="pl-1">in your hard drive</p>
          </div>
          <p className="mt-2 text-xs leading-5 text-gray-600">PDF up to 10MB</p>
        </div>
      </div>
    </div>
  );
}
