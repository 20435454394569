import {
  POST_NEW_MAIN_AMMUNITION_CATEGORY,
  POST_NEW_MAIN_AMMUNITION_CATEGORY_ERROR,
  POST_NEW_SUB_AMMUNITION_CATEGORY,
  POST_NEW_SUB_AMMUNITION_CATEGORY_ERROR,
  GET_MAIN_AMMUNITION_CATEGORY,
  GET_MAIN_AMMUNITION_CATEGORY_ERROR,
  GET_SUB_AMMUNITION_CATEGORY,
  GET_SUB_AMMUNITION_CATEGORY_ERROR,
  DELETE_MAIN_AMMUNITION_CATEGORY,
  DELETE_MAIN_AMMUNITION_CATEGORY_ERROR,
  DELETE_SUB_AMMUNITION_CATEGORY,
  DELETE_SUB_AMMUNITION_CATEGORY_ERROR,
  GET_MUNITION,
  GET_MUNITION_ERROR,
  POST_NEW_MUNITION,
  POST_NEW_MUNITION_ERROR,
  GET_MUNITION_PROPS,
  GET_MUNITION_PROPS_ERROR,
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_COMPANY,
  GET_COMPANY_ERROR,
  DELETE_MUNITION,
  DELETE_MUNITION_ERROR,
  UPDATE_MAIN_AMMUNITION_CATEGORY,
  UPDATE_MAIN_AMMUNITION_CATEGORY_ERROR,
  GET_SUB_AMMUNITION_CATEGORY_V2,
  GET_SUB_AMMUNITION_CATEGORY_ERROR_V2,
  UPDATE_SUB_AMMUNITION_CATEGORY,
  UPDATE_SUB_AMMUNITION_CATEGORY_ERROR,
  UPDATE_AMMUNITION,
  UPDATE_AMMUNITION_ERROR,
} from "../types";
import { api, token } from "../Enviroment";

import axios from "axios";

export const AddMainAmmunitionCategory = (data) => (dispatch) =>
  axios
    .post(`${api}Munition/admin/add-munition-category`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: POST_NEW_MAIN_AMMUNITION_CATEGORY,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error);
      dispatch({
        type: POST_NEW_MAIN_AMMUNITION_CATEGORY_ERROR,
        payload: error,
      });
    });

export const AddSubAmmunitionCategory = (data) => (dispatch) =>
  axios
    .post(`${api}Munition/admin/add-munition-sub-category`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: POST_NEW_SUB_AMMUNITION_CATEGORY,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error);
      dispatch({
        type: POST_NEW_SUB_AMMUNITION_CATEGORY_ERROR,
        payload: error,
      });
    });

export const getMainAmmunitionCategory = () => (dispatch) =>
  axios
    .get(`${api}Munition/main-categories/`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch({
        type: GET_MAIN_AMMUNITION_CATEGORY,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: GET_MAIN_AMMUNITION_CATEGORY_ERROR,
        payload: error,
      });
    });

export const getSubAmmunitionCategory = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      api + "Munition/munition-sub-categories/" + data,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: GET_SUB_AMMUNITION_CATEGORY,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_SUB_AMMUNITION_CATEGORY_ERROR,
      payload: error,
    });
  }
};

export const getSubAmmunitionCategoryV2 = () => async (dispatch) => {
  try {
    const response = await axios.get(api + "Munition/subcategories", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_SUB_AMMUNITION_CATEGORY_V2,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_SUB_AMMUNITION_CATEGORY_ERROR_V2,
      payload: error,
    });
  }
};

export const getMunitions = (data) => async (dispatch) => {
  try {
    const response = await axios.get(api + "Munition/munition/" + data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_MUNITION,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_MUNITION_ERROR,
      payload: error,
    });
  }
};

export const deleteMainAmmunitionCategory = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Munition/admin/remove-munition-main-category/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_MAIN_AMMUNITION_CATEGORY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: DELETE_MAIN_AMMUNITION_CATEGORY_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteSubAmmunitionCategory = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Munition/admin/remove-munition-sub-category/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_SUB_AMMUNITION_CATEGORY,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SUB_AMMUNITION_CATEGORY_ERROR,
          payload: error,
        });
      });
  };
};

export const postNewMunition = (data) => {
  return (dispatch) => {
    axios
      .post(api + "Munition/admin/add-munitions", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: POST_NEW_MUNITION,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: POST_NEW_MUNITION_ERROR,
          payload: error,
        });
      });
  };
};

export const getMunitionProps = (data) => {
  return (dispatch) => {
    axios
      .post(
        api + "Munition/munition-subcategory-props/" + data,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_MUNITION_PROPS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_MUNITION_PROPS_ERROR,
          payload: error,
        });
      });
  };
};

export const getCountries = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Map/map-countries", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_COUNTRIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNTRIES_ERROR,
      payload: error,
    });
  }
};

export const getCompanies = (data) => {
  return (dispatch) => {
    axios
      .get(api + "Company/companies", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_COMPANY_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteAmmunition = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Munition/admin/remove-munition/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_MUNITION,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_MUNITION_ERROR,
          payload: error,
        });
      });
  };
};

export const updateAmmunition = (data) => {
  return (dispatch) => {
    axios
      .put(api + "Munition/admin/update-munitions", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: UPDATE_AMMUNITION,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_AMMUNITION_ERROR,
          payload: error,
        });
      });
  };
};

export const updateMainAmmunitionCategory = (data) => {
  return (dispatch) => {
    axios
      .put(api + "Munition/admin/update-munition-main-category", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: UPDATE_MAIN_AMMUNITION_CATEGORY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_MAIN_AMMUNITION_CATEGORY_ERROR,
          payload: error,
        });
      });
  };
};

export const updateSubAmmunitionCategory = (data) => {
  return (dispatch) => {
    axios
      .put(api + "Munition/admin/update-munition-subcategory", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: UPDATE_SUB_AMMUNITION_CATEGORY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_SUB_AMMUNITION_CATEGORY_ERROR,
          payload: error,
        });
      });
  };
};
