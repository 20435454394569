import React, { useState } from "react";
import InputComponent from "../../../Components/Input";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../../Store/actions/UserAction";

const AddUser = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ipAddresses, setIpAddresses] = useState([
    { corporation: "", ipAddress: "" },
  ]);
  const [sendData, setSendData] = useState([]);
  const [successModal, setSuccessModal] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const generatePassword = (e) => {
    e.preventDefault();
    let pass = "";
    let str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
      "abcdefghijklmnopqrstuvwxyz0123456789@#$?*-/_" +
      "123456789";

    for (let i = 1; i <= 12; i++) {
      let char = Math.floor(Math.random() * str.length);
      pass += str.charAt(char);
    }

    setPassword(pass);
  };

  const addIpAddress = () => {
    setIpAddresses((prevAddresses) => [
      ...prevAddresses,
      { corporation: "", ipAddress: "" },
    ]);
  };

  const deleteSelectedPropData = (index) => {
    const newArray = [...ipAddresses];
    newArray.splice(index, 1);
    setIpAddresses(newArray);
  };

  const handleChange = (index, field, event) => {
    const newInputs = [...ipAddresses];

    newInputs[index] = {
      ...newInputs[index],
      [field]: event.target.value,
    };
    setIpAddresses(newInputs);
  };

  const handleClick = () => {
    setSendData((prevSendingData) => ({
      ...prevSendingData,
      firstName: name,
      lastName: surname,
      username: username,
      email: email,
      password: password,
      ipAddresses: ipAddresses,
    }));

    setSuccessModal(true);
  };

  const successModalButton = (e) => {
    if (e == "save") {
      setSuccessModal(false);
      console.log(JSON.stringify(sendData));
      dispatch(addUser(sendData));
      //window.location.reload(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  return (
    <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Add a User
        </h2>
      </div>
      <div className="px-4 py-5 sm:p-6 ">
        <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Name
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <InputComponent
                  type={"text"}
                  name={"name"}
                  id={"name"}
                  value={name}
                  placeholder={"Name"}
                  setValue={(e) => setName(e.target.value)}
                ></InputComponent>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Surname
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <InputComponent
                  type={"text"}
                  name={"surname"}
                  id={"surname"}
                  value={surname}
                  placeholder={"Surname"}
                  setValue={(e) => setSurname(e.target.value)}
                ></InputComponent>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Username
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <InputComponent
                  type={"text"}
                  name={"username"}
                  id={"username"}
                  value={name}
                  placeholder={"Username"}
                  setValue={(e) => setUsername(e.target.value)}
                ></InputComponent>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Email
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <InputComponent
                  type={"text"}
                  name={"email"}
                  id={"email"}
                  value={email}
                  placeholder={"Email"}
                  setValue={(e) => setEmail(e.target.value)}
                ></InputComponent>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Password
              </label>
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  defaultValue={password}
                  name={"password"}
                  id={"password"}
                  placeholder={"Password"}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className=" w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  onClick={generatePassword}
                  type="button"
                  className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Generate Password
                </button>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Ip Address
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                {ipAddresses?.map((item, index) => (
                  <div className="mt-2 sm:col-span-1 sm:mt-0" key={index}>
                    <InputComponent
                      name={"corporation"}
                      id={"corporation"}
                      placeholder={"Corporation"}
                      setValue={(event) =>
                        handleChange(index, "corporation", event)
                      }
                    ></InputComponent>
                    <InputComponent
                      name={"ipAddress"}
                      id={"ipAddress"}
                      placeholder={"Ip Address"}
                      setValue={(event) =>
                        handleChange(index, "ipAddress", event)
                      }
                    >
                      {ipAddresses.length > 1 && (
                        <button
                          onClick={() => deleteSelectedPropData(index)}
                          type="button"
                          className="relative ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <MinusCircleIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      )}

                      {index == ipAddresses.length - 1 && (
                        <button
                          onClick={() => addIpAddress()}
                          type="button"
                          className="relative ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <PlusCircleIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      )}
                    </InputComponent>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleClick}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default AddUser;
