import React, { useState, useEffect } from "react";
import InputComponent from "../../../Components/Input";
import SelectComponent from "../../../Components/Selectbox";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import TextArea from "../../../Components/Textarea";
import { useLocation } from "react-router-dom";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import PhotoUpload from "../../../Components/PhotoUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryDetail,
  getCountryProps,
  getContinents,
  getFormOfGoverment,
  getRegions,
  getOrganization,
  updateCountry,
} from "../../../Store/actions/CountryAction";

const EditAmmunition = () => {
  const dispatch = useDispatch();
  const [successModal, setSuccessModal] = useState(false);
  const [dynamicSelectboxData, setdynamicSelectboxData] = useState([]);
  const [dynamicContentValues, setDynamicComponentsValues] = useState([]);
  const [sendingData, setSendingData] = useState([]);
  const [flag, setFlag] = useState("");

  // -------------------------------------------

  const countryListData = useSelector((state) => state.countriesList);
  const { loading, error, countryDetail } = countryListData;

  const countryPropsData = useSelector((state) => state.countriesList);
  const { loading1, error1, countryProps } = countryPropsData;

  const continentsData = useSelector((state) => state.countriesList);
  const { loading2, error2, continents } = continentsData;

  const formOfGovermentData = useSelector((state) => state.countriesList);
  const { loading3, error3, formOfGoverment } = formOfGovermentData;

  const regionsData = useSelector((state) => state.countriesList);
  const { loading4, error4, regions } = regionsData;

  const organizationData = useSelector((state) => state.countriesList);
  const { loading5, error5, organizations } = organizationData;

  // -------------------------------------------------

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    dispatch(getContinents());
    dispatch(getFormOfGoverment());
    dispatch(getRegions());
    dispatch(getOrganization());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const code = query.get("code");
        if (code) {
          await dispatch(getCountryDetail(code));
          await dispatch(getCountryProps());

          import("../../../Source/flags/" + code.toLowerCase() + ".svg").then(
            (image) => {
              setFlag(image.default);
            }
          );
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [dispatch, query, countryDetail]);

  const successModalButton = (e) => {
    if (e == "save") {
      setSuccessModal(false);
      console.log(JSON.stringify(sendingData));
      dispatch(updateCountry(sendingData));
      window.location.reload(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  const getDataFromDynamicComponentSelect = (e, name, componentType) => {
    setdynamicSelectboxData({
      ...dynamicSelectboxData,
      [name]: componentType === "SingleSelect" ? e : e.map((x) => ({ id: x })),
    });
  };

  const getDynamicContentValue = (event, dbName, component) => {
    if (component == "Json") {
      setDynamicComponentsValues({
        ...dynamicContentValues,
        [dbName]: { ["value"]: event.target.value },
      });
    } else {
      setDynamicComponentsValues({
        ...dynamicContentValues,
        [dbName]: event.target.value,
      });
    }
  };

  const saveButton = async () => {
    let allData = {};

    let inputKeys = Object.keys(dynamicContentValues);
    let inputValues = Object.values(dynamicContentValues);

    let selectKeys = Object.keys(dynamicSelectboxData);
    let selectValues = Object.values(dynamicSelectboxData);

    const allDataKeys = [...inputKeys, ...selectKeys];

    let productPropKeys = Object.keys(countryDetail);
    let productKeys = Object.keys(countryDetail);

    const missingPropKeys = productPropKeys.filter(
      (property) => !allDataKeys.includes(property)
    );

    missingPropKeys.forEach((element) => {
      allData[element] = countryDetail[element];
    });

    const missingProductKeys = productKeys.filter(
      (property) => !allDataKeys.includes(property)
    );

    missingProductKeys.forEach((element) => {
      allData[element] = countryDetail[element];
    });

    inputKeys.forEach((element, index) => {
      allData[element] = inputValues[index];
    });

    selectKeys.forEach((element, index) => {
      allData[element] = selectValues[index];
    });

    console.log(countryDetail);

    let selectedData = {
      id: countryDetail.id,
      primeMinister: allData.primeMinister,
      secretaryOfDefence: allData.secretaryOfDefence,
      president: allData.president,
      code: allData.code,
      capitalCityLong: allData.capitalCityLong,
      capitalCityLat: allData.capitalCityLat,
      trainingAircraftCount: {
        prop: "air",
        value: allData.trainingAircraftCount?.value,
      },
      guidedMissileBoatCount: {
        prop: "naval",
        value: allData.guidedMissileBoatCount?.value,
      },
      combatAircraftCount: {
        prop: "air",
        value: allData.trainingAircraftCount?.value,
      },
      militaryPersonelCount: {
        prop: "land",
        value: allData.militaryPersonelCount?.value,
      },
      submarineCount: {
        prop: "naval",
        value: allData.submarineCount?.value,
      },
      rangedAirDefenceCount: {
        prop: "land",
        value: allData.rangedAirDefenceCount?.value,
      },
      tacticStrategicUavCount: {
        prop: "air",
        value: allData.tacticStrategicUavCount?.value,
      },
      utilityHelicoptersCount: {
        prop: "air",
        value: allData.utilityHelicoptersCount?.value,
      },
      nuclearSubmarineCount: {
        prop: "naval",
        value: allData.nuclearSubmarineCount?.value,
      },
      mainBattleTankCount: {
        prop: "land",
        value: allData.mainBattleTankCount?.value,
      },
      aircraftCarriesCount: {
        prop: "naval",
        value: allData.aircraftCarriesCount?.value,
      },
      armouredPersonelCarrierCount: {
        prop: "land",
        value: allData.armouredPersonelCarrierCount?.value,
      },
      attackHelicopterCount: {
        prop: "air",
        value: allData.attackHelicopterCount?.value,
      },
      mineLayerCount: {
        prop: "naval",
        value: allData.mineLayerCount?.value,
      },
      transportPlaneCount: {
        prop: "air",
        value: allData.transportPlaneCount?.value,
      },
      fuelSupplyShipCount: {
        prop: "naval",
        value: allData.fuelSupplyShipCount?.value,
      },
      capitalCity: allData.capitalCity,
      armouredFightingVehicleCount: {
        prop: "land",
        value: allData.armouredFightingVehicleCount?.value,
      },
      acreage: allData.acreage,
      defenceBudget: allData.defenceBudget,
      mlrsCount: { prop: "land", value: allData.mlrsCount?.value },
      budgetRadio: allData.budgetRadio,
      balisticMissileCount: {
        prop: "air",
        value: allData.balisticMissileCount?.value,
      },
      nuclearGeneralSubmarineCount: {
        prop: "naval",
        value: allData.nuclearGeneralSubmarineCount?.value,
      },
      amphibiousAssaultShipCount: {
        prop: "naval",
        value: allData.amphibiousAssaultShipCount?.value,
      },
      gdp: allData.gdp,
      artileryWeaponCount: {
        prop: "land",
        value: allData.artileryWeaponCount?.value,
      },
      warPlaneCount: {
        prop: "air",
        value: allData.warPlaneCount?.value,
      },
      surfaceWarshipCount: {
        prop: "naval",
        value: allData.surfaceWarshipCount?.value,
      },
      cruiseMissileCount: {
        prop: "air",
        value: allData.cruiseMissileCount?.value,
      },
      chiefOfStaff: allData.chiefOfStaff,
      originalName: allData.originalName,
      populationCount: allData.populationCount,
      largeWarshipCount: {
        prop: "naval",
        value: allData.largeWarshipCount?.value,
      },
      formOfGoverments: allData.formOfGoverment?.id,
      continents: allData.continent?.id,
      regions: allData.regions
        ? allData.regions
        : countryDetail["countryRegions"].map((x) => ({ id: x.region.id })),
      organizations: allData.organizations
        ? allData.organizations
        : countryDetail["countryOrganizations"].map((x) => ({
            id: x.organization.id,
          })),
    };

    setSendingData((prevSendingData) => {
      return {
        ...prevSendingData,
        ...selectedData,
      };
    });

    setSuccessModal(true);

    //}
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
            <div className="px-4 py-5 sm:px-6">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Edit Country
              </h2>
            </div>
            <div className="px-4 py-5 sm:p-6 ">
              <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"></label>
                    <div className="mt-2 sm:col-span-1 sm:mt-0">
                      <img
                        style={{
                          height: 200,
                          width: 400,
                        }}
                        src={flag}
                      />
                    </div>
                  </div>

                  {countryProps?.map((x) => {
                    return x.type == "productPropName" ? (
                      ""
                    ) : (
                      <div
                        key={x.id}
                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
                      >
                        <label className="ml-10 block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                          {x.normalizedPropName}
                        </label>
                        <div className="mt-3 sm:col-span-1 sm:mt-0">
                          {x.component == "Input" ? (
                            <InputComponent
                              key={x.id}
                              type={
                                x.type == "integer"
                                  ? "number"
                                  : x.type == "string"
                                  ? "text"
                                  : x.type == "float"
                                  ? "number"
                                  : "text"
                              }
                              name={x.normalizedPropName}
                              id={x.id}
                              placeholder={x.normalizedPropName}
                              setValue={(e) =>
                                getDynamicContentValue(e, x.propName)
                              }
                              selectedValue={
                                countryDetail && countryDetail[x.propName]
                              }
                            ></InputComponent>
                          ) : x.component == "Json" ? (
                            <InputComponent
                              key={x.id}
                              type={
                                x.type == "integer"
                                  ? "number"
                                  : x.type == "string"
                                  ? "text"
                                  : x.type == "float"
                                  ? "float"
                                  : "text"
                              }
                              name={x.normalizedPropName}
                              id={x.id}
                              placeholder={x.normalizedPropName}
                              setValue={(e) =>
                                getDynamicContentValue(
                                  e,
                                  x.propName,
                                  x.component
                                )
                              }
                              selectedValue={
                                countryDetail &&
                                countryDetail[x.propName]?.value
                              }
                            ></InputComponent>
                          ) : x.component == "SingleSelect" ? (
                            <SingleSelectComponent
                              options={
                                x.propName == "formOfGoverments"
                                  ? formOfGoverment
                                  : x.propName == "continents"
                                  ? continents
                                  : []
                              }
                              onChange={(e) =>
                                getDataFromDynamicComponentSelect(
                                  e,
                                  x.propName,
                                  x.component
                                )
                              }
                              selectedValue={
                                x.propName == "formOfGoverments"
                                  ? countryDetail?.["formOfGoverment"]["id"]
                                  : x.propName == "continents"
                                  ? countryDetail?.["continent"]["id"]
                                  : []
                              }
                            />
                          ) : x.component == "Select" ? (
                            <SelectComponent
                              options={
                                x.propName == "regions"
                                  ? regions
                                  : x.propName == "organizations"
                                  ? organizations
                                  : []
                              }
                              selectedValues={
                                x.propName == "regions"
                                  ? countryDetail["countryRegions"]?.map(
                                      (item) => item.region.id
                                    )
                                  : x.propName == "organizations"
                                  ? countryDetail["countryOrganizations"]?.map(
                                      (item) => item?.organization.id
                                    )
                                  : []
                              }
                              onChange={(e) =>
                                getDataFromDynamicComponentSelect(
                                  e,
                                  x.propName,
                                  x.component
                                )
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={saveButton}
                    type="button"
                    className={`rounded-md bg-indigo-600
                 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500
                 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>

          {successModal == true && (
            <div>
              <SuccessAlert
                mainTitle={" Data is ready to save"}
                text={"Are you sure you want to save?"}
                visible={successModal}
                childToParent={successModalButton}
              ></SuccessAlert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAmmunition;
