import {
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_ERROR,
  POST_NEW_COMPANY,
  POST_NEW_COMPANY_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
} from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const getCompanies = (data) => {
  return (dispatch) => {
    axios
      .get(api + "Company/companies", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_COMPANY_ERROR,
          payload: error,
        });
      });
  };
};

export const getCompaniesDetail = (data) => {
  return (dispatch) => {
    axios
      .get(api + "Company/companies/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANY_DETAIL,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_COMPANY_DETAIL_ERROR,
          payload: error,
        });
      });
  };
};

export const addCompany = (data) => {
  return (dispatch) => {
    axios
      .post(`${api}Company/admin/add-company`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: POST_NEW_COMPANY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: POST_NEW_COMPANY_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteCompany = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Company/admin/remove-company/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_COMPANY,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_COMPANY_ERROR,
          payload: error,
        });
      });
  };
};

export const updateCompany = (data) => {
  return (dispatch) => {
    axios
      .put(api + "Company/admin/update-company", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: UPDATE_COMPANY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: UPDATE_COMPANY_ERROR,
          payload: error,
        });
      });
  };
};
