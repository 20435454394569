import React, { useState, useEffect, useRef } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/20/solid";
import InputComponent from "../../../Components/Input";
import SelectComponent from "../../../Components/Selectbox";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import TextArea from "../../../Components/Textarea";
import SwitchComponent from "../../../Components/Switch";
import WarningAlert from "../../../Components/WarningAlertModal";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import PhotoUpload from "../../../Components/PhotoUpload";
import AddableComponent from "../../../Components/AddableComponent";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getCategories,
  getSubCategories,
  getSubCategoryProps,
  getSubCategorySelectBoxData,
  getProductDetails,
} from "../../../Store/actions/CategoriesAction";
import { getCountries } from "../../../Store/actions/CountryAction";
import {
  postProductFile,
  updateProduct,
} from "../../../Store/actions/ProductAction";

const AddProduct = () => {
  const dispatch = useDispatch();
  const [warningModal, setWarningModal] = useState(false);
  const [photo, setPhoto] = useState([]);
  const [newPhoto, setNewPhoto] = useState([]);
  const [addableComponentData, setAddableComponentData] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [checkedData, setCheckedData] = useState(false);
  const [photoLink, setPhotoLink] = useState([]);
  const [dynamicSelectboxData, setdynamicSelectboxData] = useState([]);
  const [dynamicContentValues, setDynamicComponentsValues] = useState([]);
  const [dynamicSwitchData, setDynamicSwitchData] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [productId, setProductId] = useState();
  const [sendingData, setSendingData] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState([]);
  const [selectedAddableProductId, setSelectedAddableProductId] = useState([]);
  const [detailedInventoriesData, setDetailedInventoriesData] = useState([
    {
      countryId: "",
      number: "",
    },
  ]);

  const [selectedDetailedInventory, setSelectedDetailedInventory] = useState(
    []
  );

  // -------------------------------------------
  const categoriesListData = useSelector((state) => state.categoryList);
  const { categoriesLoad, categoriesErr, categories } = categoriesListData;

  const countryListData = useSelector((state) => state.countriesList);
  const { loading, error, countries } = countryListData;

  const subCategoriesListData = useSelector((state) => state.subCategoryList);
  const {
    subCategoriesByCategoryLoad,
    subCategoriesByCategoryErr,
    subCategoriesByCategory,
  } = subCategoriesListData;

  const subCategoryPropsList = useSelector((state) => state.subCategoryProps);
  const { subCategoryPropsLoad, subCategoryPropsErr, subCategoryProps } =
    subCategoryPropsList;

  const productDetailList = useSelector((state) => state.productDetailList);
  const { productDetailLoad, productDetailErr, productDetail } =
    productDetailList;

  const subCategorySelectboxDataListData = useSelector(
    (state) => state.subCategorySelectboxDataList
  );
  const {
    subCategorySelectboxDataLoad,
    subCategorySelectboxDataErr,
    subCategorySelectboxData,
  } = subCategorySelectboxDataListData;

  // -------------------------------------------------

  const addNewDetailedInventoriesInput = () => {
    setDetailedInventoriesData([
      ...detailedInventoriesData,
      { countryId: "", number: "" },
    ]);
  };

  const deleteDetailedInventoriesInput = (index) => {
    const newArray = [...detailedInventoriesData];
    newArray.splice(index, 1);
    setDetailedInventoriesData(newArray);
    setSelectedDetailedInventory(newArray);
  };

  const getDetailedInventoryData = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...detailedInventoriesData];

    let test = selectedCountryId.find((element, i) => i === index);

    onChangeValue[index] = {
      countryId:
        test != undefined
          ? selectedCountryId.find((element, i) => i === index).countryId
          : detailedInventoriesData.find((element, i) => i === index).countryId,
      [name]: parseInt(value),
    };

    setSelectedDetailedInventory(onChangeValue);
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCountries());
    setPhotoLink("");

    let getQueryProductId = query.get("productId");
    let getQueryCategoryId = query.get("categoryId");
    let getQuerySubCategoryId = query.get("subCategoryId");

    setTimeout(() => {
      dispatch(getSubCategories(getQueryCategoryId));
      dispatch(getSubCategorySelectBoxData(getQuerySubCategoryId));
      dispatch(getSubCategoryProps(getQuerySubCategoryId));
      dispatch(getProductDetails(getQuerySubCategoryId, getQueryProductId));

      setCategoryId(getQueryCategoryId);
      setSubCategoryId(getQuerySubCategoryId);
      setProductId(getQueryProductId);
    }, 1000);
  }, []);

  useEffect(() => {
    if (productDetail && productDetail.detailedInventories) {
      setSelectedDetailedInventory((prevData) =>
        productDetail.detailedInventories.map((item) => ({
          countryId: item.countryId,
          number: item.number,
        }))
      );

      if (productDetail.detailedInventories.length != 0) {
        setDetailedInventoriesData((prevData) =>
          productDetail.detailedInventories.map((item) => ({
            countryId: item.countryId,
            number: item.number,
          }))
        );
      }

      productDetail.detailedInventories.map((item) => {
        setSelectedCountryId((prevSelectedCountryIds) => [
          ...prevSelectedCountryIds,
          {
            countryId: item.countryId,
            number: null,
          },
        ]);
      });

      setPhotoLink((prevData) => productDetail.images?.map((item) => item.url));
    }
  }, [productDetail]);

  const successModalButton = (e) => {
    if (e == "save") {
      setSuccessModal(false);
      //console.log(sendingData);
      dispatch(updateProduct(sendingData));
      window.location.reload(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  const getDataFromCategorySelect = (data) => {
    setCategoryId(data);

    dispatch(getSubCategories(data));
  };

  const getDataFromCountrySelect = (data) => {
    setSelectedCountryId((prevSelectedCountryIds) => [
      ...prevSelectedCountryIds,
      {
        countryId: data,
        number: null,
      },
    ]);
  };

  const getDataFromSubCategorySelect = async (data) => {
    setSubCategoryId(data);

    dispatch(getSubCategorySelectBoxData(data));
    dispatch(getSubCategoryProps(data));
  };

  const getDataFromDynamicComponentSelect = (e, name, componentType) => {
    setdynamicSelectboxData({
      ...dynamicSelectboxData,
      [name]: componentType === "SingleSelect" ? e : e.map((x) => ({ id: x })),
    });
  };

  const getDataFromAddableComponent = (e, prop) => {
    let object = {
      [prop]: e.map((x) => ({
        id: x[prop].id,
        count: x[prop].count,
      })),
    };

    const existingObjectIndex = addableComponentData.findIndex(
      (obj) => obj[prop]
    );

    if (existingObjectIndex !== -1) {
      addableComponentData[existingObjectIndex][prop] = [...object[prop]];
    } else {
      setAddableComponentData((prevData) => [...prevData, { ...object }]);
    }
  };

  const getDynamicContentValue = (event, dbName, component) => {
    if (component == "Json") {
      setDynamicComponentsValues({
        ...dynamicContentValues,
        [dbName]: { ["value"]: event.target.value },
      });
    } else {
      setDynamicComponentsValues({
        ...dynamicContentValues,
        [dbName]: event.target.value,
      });
    }
  };

  const getDynamicSwitchData = (e, propName) => {
    setDynamicSwitchData({
      ...dynamicSwitchData,
      [propName]: e,
    });
  };

  const getImageData = async (data) => {
    if (data.target.files != null) {
      const fileList = data.target.files;
      const fileArray = Array.from(fileList);

      setPhoto((prevPhotos) => [...prevPhotos, ...fileArray]);

      setNewPhoto((prevPhotos) => [
        ...prevPhotos,
        ...fileArray.map((file) => URL.createObjectURL(file)),
      ]);
    }
  };

  const deleteFile = (e) => {
    const updatedImages = [...photoLink, ...newPhoto];
    updatedImages.splice(e, 1);
    setPhotoLink(updatedImages);
    setNewPhoto([]);
  };

  const warningModalButton = (e) => {
    setWarningModal(e);
  };

  const getAddableProductId = (e, prop) => {
    let object = {
      [prop]: e.map((x) => ({
        id: x,
        count: null,
      })),
    };

    const existingObjectIndex = selectedAddableProductId.findIndex(
      (obj) => obj[prop]
    );

    if (existingObjectIndex !== -1) {
      selectedAddableProductId[existingObjectIndex][prop] = [...object[prop]];
    } else {
      setSelectedAddableProductId((prevData) => [...prevData, { ...object }]);
    }
  };

  const mergeAndRemoveDuplicatesAddables = () => {
    let newAddableComponentData = [...addableComponentData];
    let newSelectedAddableProductId = [...selectedAddableProductId];

    newSelectedAddableProductId.forEach((selectedItem, index) => {
      Object.keys(selectedItem).forEach((prop) => {
        const propExistsInAddable = newAddableComponentData.findIndex(
          (item) => item[prop] !== undefined
        );
        if (propExistsInAddable !== -1) {
          const newPropItems = selectedItem[prop].filter(
            (selectedPropItem) =>
              !newAddableComponentData[propExistsInAddable][prop].some(
                (addablePropItem) => addablePropItem.id === selectedPropItem.id
              )
          );

          if (newPropItems.length > 0) {
            newAddableComponentData[propExistsInAddable][prop] = [
              ...newAddableComponentData[propExistsInAddable][prop],
              ...newPropItems,
            ];
          }
          newSelectedAddableProductId[index][prop] = [];
        }
      });
    });

    newSelectedAddableProductId = newSelectedAddableProductId.filter((item) =>
      Object.keys(item).some((prop) => item[prop].length > 0)
    );

    setAddableComponentData(newAddableComponentData);
    setSelectedAddableProductId(newSelectedAddableProductId);
  };

  const saveButton = async () => {
    //   if (
    //     categoryId == null ||
    //     subCategoryId == null ||
    //     Object.entries(dynamicContentValues).length <
    //       subCategoryProps.filter((x) => x.component === "Input").length
    //   ) {
    //     setWarningModal(false);
    //   } else {
    let normalizeName = subCategoryProps.find(
      (res) => res.type === "productPropName"
    );

    let allData = {};
    let addableAllData = {};
    let originalNameData = "";
    let originalTextData = "";
    let countriesData = [];
    let companiesData = [];
    let status = "";
    let isInMuseum = "";
    let detailedInventory = [];

    let inputKeys = Object.keys(dynamicContentValues);
    let inputValues = Object.values(dynamicContentValues);

    let selectKeys = Object.keys(dynamicSelectboxData);
    let selectValues = Object.values(dynamicSelectboxData);

    let switchKeys = Object.keys(dynamicSwitchData);
    let switchValues = Object.values(dynamicSwitchData);

    let isInMuseumCheck = switchKeys.includes("isInMuseum");
    let statusCheck = switchKeys.includes("status");

    isInMuseum =
      isInMuseumCheck && switchValues[switchKeys.indexOf("isInMuseum")];
    status = statusCheck && switchValues[switchKeys.indexOf("status")];

    const allDataKeys = [...inputKeys, ...selectKeys, ...switchKeys];

    let productPropKeys = Object.keys(productDetail[normalizeName.propName][0]);
    let productKeys = Object.keys(productDetail);

    const missingPropKeys = productPropKeys.filter(
      (property) => !allDataKeys.includes(property)
    );

    missingPropKeys.forEach((element) => {
      if (element !== "originalName" && element !== "originalText") {
        allData[element] = productDetail[normalizeName.propName][0][element];
      }
    });

    const missingProductKeys = productKeys.filter(
      (property) => !allDataKeys.includes(property)
    );

    missingProductKeys.forEach((element) => {
      if (element === "originalName") {
        originalNameData = productDetail[element];
      } else if (element === "originalText") {
        originalTextData = productDetail[element];
      } else if (element === "countries") {
        countriesData.push(productDetail[element]);
      } else if (element === "companies") {
        companiesData.push(productDetail[element]);
      } else if (element === "detailedInventories") {
        detailedInventory = productDetail[element];
      } else if (element === "status") {
        status = productDetail[element];
      } else if (element === "isInMuseum") {
        isInMuseumCheck = productDetail[element];
      }
    });

    inputKeys.forEach((element, index) => {
      if (element === "originalName" && originalNameData == "") {
        originalNameData = inputValues[index];
      } else if (element === "originalText" && originalTextData == "") {
        originalTextData = inputValues[index];
      }
    });

    switchKeys.forEach((element, index) => {
      if (element !== "isInMuseum" && element !== "status") {
        allData[element] = switchValues[index];
      }
    });

    selectKeys.forEach((element, index) => {
      if (element !== "companies" && element !== "countries") {
        allData[element] = selectValues[index];
      } else if (element === "countries") {
        countriesData.push(selectValues[index]);
      } else if (element === "companies") {
        companiesData.push(selectValues[index]);
      }
    });

    selectKeys.forEach((element, index) => {
      if (element !== "companies" && element !== "countries") {
        allData[element] = selectValues[index];
      } else if (element === "countries") {
        countriesData.push(selectValues[index]);
      } else if (element === "companies") {
        companiesData.push(selectValues[index]);
      }
    });

    const formDataArray = [];
    const photoDataArray = [];

    for (let getFile of photo) {
      const formData = new FormData();
      const file = getFile;
      const fileName = getFile.name;

      formData.append("file", file);
      formData.append("fileName", fileName);
      formDataArray.push({ formData, fileName });
    }

    await Promise.all(
      formDataArray.map(({ formData }) => dispatch(postProductFile(formData)))
    );

    formDataArray.forEach(({ fileName }) => {
      photoDataArray.push(fileName);
    });

    mergeAndRemoveDuplicatesAddables();

    addableComponentData.forEach((x) => {
      addableAllData = { ...addableAllData, ...x };
    });

    //************************ */

    const detailedInventoryCountryIds = selectedDetailedInventory.map(
      (item) => item.countryId
    );

    const filteredSelectedCountryId = selectedCountryId.filter(
      ({ countryId }) => !detailedInventoryCountryIds.includes(countryId)
    );

    const mergedData = [
      ...selectedDetailedInventory,
      ...filteredSelectedCountryId.map((x) => ({
        countryId: x.countryId,
        number: null,
      })),
    ];

    detailedInventory = mergedData;

    //************************ */

    setSendingData((prevSendingData) => {
      return {
        ...prevSendingData,
        [normalizeName.propName]: [
          {
            ...allData,
            ...addableAllData,
          },
        ],
        id: productDetail["id"],
        originalName: originalNameData,
        originalText: originalTextData,
        images: [
          ...photoLink.map((x) => ({
            url: x,
          })),
          ...photoDataArray.map((fileName) => ({
            url: "https://cdn.mildata.net/data/" + fileName,
          })),
        ],
        productMainCategoryId: categoryId,
        productSubCategoryId: subCategoryId,
        status: status,
        isInMuseum: isInMuseum,
        detailedInventories: detailedInventory,
        companies: companiesData[0],
        countries: countriesData[0],
      };
    });

    setSuccessModal(true);
    //}
  };

  return (
    <div className="divide-y divide-gray-300 rounded-lg bg-white shadow border border-gray-900/50">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Edit Product
        </h2>
      </div>
      <div className="px-4 py-5 sm:p-6 ">
        <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Category
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <SingleSelectComponent
                  options={categories}
                  onChange={getDataFromCategorySelect}
                  selectedValue={
                    productDetail && productDetail.productMainCategoryId
                  }
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Sub Category
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <SingleSelectComponent
                  options={subCategoriesByCategory}
                  onChange={getDataFromSubCategorySelect}
                  selectedValue={
                    productDetail && productDetail.productSubCategoryId
                  }
                />
              </div>
            </div>

            {subCategoryProps?.map((x) => {
              return x.type == "productPropName" ? (
                ""
              ) : (
                <div
                  key={x.id}
                  className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
                >
                  <label className="ml-10 block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                    {x.normalizedPropName}
                  </label>
                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                    {x.component == "Input" ? (
                      <InputComponent
                        key={x.id}
                        type={
                          x.type == "integer"
                            ? "number"
                            : x.type == "string"
                            ? "text"
                            : x.type == "float"
                            ? "number"
                            : "text"
                        }
                        name={x.normalizedPropName}
                        id={x.id}
                        selectedValue={
                          x.propName == "originalName"
                            ? productDetail[x.propName]
                            : x.propName == "originalText"
                            ? productDetail[x.propName]
                            : productDetail[
                                subCategoryProps.find(
                                  (y) => y.type == "productPropName"
                                ).propName
                              ]?.[0][x.propName]
                        }
                        placeholder={x.normalizedPropName}
                        setValue={(e) => getDynamicContentValue(e, x.propName)}
                      ></InputComponent>
                    ) : x.component == "Json" ? (
                      <InputComponent
                        key={x.id}
                        type={
                          x.type == "integer"
                            ? "number"
                            : x.type == "string"
                            ? "text"
                            : x.type == "float"
                            ? "float"
                            : "text"
                        }
                        name={x.normalizedPropName}
                        id={x.id}
                        selectedValue={
                          productDetail[
                            subCategoryProps.find(
                              (y) => y.type == "productPropName"
                            ).propName
                          ]?.[0][x.propName].value
                        }
                        placeholder={x.normalizedPropName}
                        setValue={(e) =>
                          getDynamicContentValue(e, x.propName, x.component)
                        }
                      ></InputComponent>
                    ) : x.component == "AddableComponent" ? (
                      <AddableComponent
                        array={subCategorySelectboxData[x.propName]}
                        selectedValue={
                          productDetail[
                            subCategoryProps?.find(
                              (y) => y.type === "productPropName"
                            ).propName
                          ]?.[0][x.propName]
                        }
                        prop={x.propName}
                        childToParent={(e) =>
                          getDataFromAddableComponent(e, x.propName)
                        }
                        selectedProductId={(e) =>
                          getAddableProductId(e, x.propName)
                        }
                      ></AddableComponent>
                    ) : x.component == "DetailedInventories" ? (
                      <div>
                        {detailedInventoriesData?.map((item, index) => (
                          <div
                            className="mt-2 sm:col-span-1 sm:mt-0"
                            key={index}
                          >
                            <SingleSelectComponent
                              options={countries}
                              onChange={getDataFromCountrySelect}
                              selectedValue={item?.countryId}
                            />
                            <InputComponent
                              type={"text"}
                              name={"number"}
                              id={"number"}
                              placeholder={"Count"}
                              selectedValue={item?.number}
                              setValue={(event) =>
                                getDetailedInventoryData(event, index)
                              }
                            >
                              {detailedInventoriesData.length > 1 && (
                                <button
                                  onClick={() =>
                                    deleteDetailedInventoriesInput(index)
                                  }
                                  type="button"
                                  className="mb-7 ml-3 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <MinusCircleIcon
                                    className="-ml-0.5 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}

                              {index == detailedInventoriesData.length - 1 && (
                                <button
                                  onClick={() =>
                                    addNewDetailedInventoriesInput()
                                  }
                                  type="button"
                                  className="mb-7 ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <PlusCircleIcon
                                    className="-ml-0.5 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                            </InputComponent>
                          </div>
                        ))}
                      </div>
                    ) : x.component == "Textarea" ? (
                      <TextArea
                        key={x.id}
                        type={x.type}
                        name={x.normalizedPropName}
                        id={x.id}
                        selectedValue={
                          x.propName == "originalName"
                            ? productDetail[x.propName]
                            : x.propName == "originalText"
                            ? productDetail[x.propName]
                            : productDetail[
                                subCategoryProps.find(
                                  (y) => y.type == "productPropName"
                                ).propName
                              ]?.[0][x.propName]
                        }
                        placeholder={x.normalizedPropName}
                        setValue={(e) => getDynamicContentValue(e, x.propName)}
                      ></TextArea>
                    ) : x.component == "Select" ? (
                      <SelectComponent
                        options={subCategorySelectboxData[x.propName]}
                        selectedValues={
                          x.propName == "countries"
                            ? productDetail[x.propName]?.map((item) => item.id)
                            : x.propName == "companies"
                            ? productDetail[x.propName]?.map((item) => item.id)
                            : productDetail[
                                subCategoryProps?.find(
                                  (y) => y.type === "productPropName"
                                ).propName
                              ]?.[0][x.propName]?.map((item) => item.id)
                        }
                        onChange={(e) =>
                          getDataFromDynamicComponentSelect(
                            e,
                            x.propName,
                            x.component
                          )
                        }
                      />
                    ) : x.component == "SingleSelect" ? (
                      <SingleSelectComponent
                        options={
                          x.propName === "airToAirMissile"
                            ? subCategorySelectboxData["airToAirMissiles"]
                            : x.propName === "airToSurfaceMissile"
                            ? subCategorySelectboxData["airToSurfaceMissiles"]
                            : subCategorySelectboxData[x.propName]
                        }
                        onChange={(e) =>
                          getDataFromDynamicComponentSelect(
                            e,
                            x.propName,
                            x.component
                          )
                        }
                        selectedValue={
                          productDetail &&
                          productDetail[
                            subCategoryProps?.find(
                              (y) => y.type === "productPropName"
                            ).propName
                          ]?.[0][x.propName]
                        }
                      />
                    ) : x.component == "Upload" ? (
                      <>
                        <PhotoUpload
                          isMultiple={true}
                          name={"photo"}
                          id={"photo"}
                          type={"file"}
                          value={(e) => getImageData(e)}
                        />

                        <div className="mt-2 grid grid-cols-2 gap-2 justify-center">
                          {photoLink ? (
                            [...photoLink, ...newPhoto].map((file, index) => (
                              <div
                                key={index}
                                className="flex flex-col items-center"
                              >
                                <img
                                  style={{
                                    height: 200,
                                    width: 200,
                                    margin: 10,
                                    marginBottom: 15,
                                  }}
                                  src={file}
                                />
                                <button
                                  onClick={() => deleteFile(index)}
                                  type="button"
                                  className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                  Delete
                                </button>
                              </div>
                            ))
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </>
                    ) : x.component == "Switch" ? (
                      <SwitchComponent
                        switchLabel={x.propName}
                        checked={
                          productDetail[x.propName] ||
                          productDetail[
                            subCategoryProps.find(
                              (y) => y.type == "productPropName"
                            ).propName
                          ]?.[0][x.propName]
                        }
                        childToParent={(e) => {
                          setCheckedData(e);
                          getDynamicSwitchData(e, x.propName);
                        }}
                      ></SwitchComponent>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={saveButton}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      {warningModal == true && (
        <div>
          <WarningAlert
            visible={warningModal}
            childToParent={warningModalButton}
            text={"Please fill in the blank fields"}
            title={"Empty Data"}
          ></WarningAlert>
        </div>
      )}

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default AddProduct;