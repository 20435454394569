import React, { useState } from "react";
import InputComponent from "../../../Components/Input";
import SelectComponent from "../../../Components/Selectbox";

const AddTrade = () => {
  const [sellerCountry, setSellerCountry] = useState("");
  const [buyerCountry, setBuyerCountry] = useState("");
  const [product, setProduct] = useState("");
  const [category, setCategory] = useState("");
  const [year, setYear] = useState("");
  const [count, setCount] = useState("");

  const handleClick = () => {
    // console.log(sellerCountry);
    // console.log(buyerCountry);
    // console.log(product);
    // console.log(category);
    // console.log(year);
    // console.log(count);
  };

  const country = [
    {
      id: 1,
      title: "Country1",
    },
    {
      id: 2,
      title: "Country2",
    },
    {
      id: 3,
      title: "Country3",
    },
  ];

  const categories = [
    {
      id: 2,
      title: "Category1",
    },
    {
      id: 1,
      title: "Category2",
    },
    {
      id: 3,
      title: "Category3",
    },
  ];

  const products = [
    {
      id: 1,
      title: "Product1",
    },
    {
      id: 2,
      title: "Product2",
    },
    {
      id: 3,
      title: "Product3",
    },
  ];

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Add a Arms Trade
          </h2>
        </div>
        <div className="px-4 py-5 sm:p-6 ">
          <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Product
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SelectComponent
                    inputId={"product"}
                    name={"product"}
                    array={products}
                    value={(e) => setProduct(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Seller Country
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SelectComponent
                    inputId={"sellerCountry"}
                    name={"sellerCountry"}
                    array={country}
                    value={(e) => setSellerCountry(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Buyer Country
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SelectComponent
                    inputId={"buyerCountry"}
                    name={"buyerCountry"}
                    array={country}
                    value={(e) => setBuyerCountry(e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Category
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SelectComponent
                    inputId={"category"}
                    name={"category"}
                    array={categories}
                    value={(e) => setCategory(e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Year
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"year"}
                    id={"year"}
                    value={year}
                    placeholder={"Year"}
                    setValue={(e) => setYear(e.target.value)}
                  ></InputComponent>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Count
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"count"}
                    id={"count"}
                    value={count}
                    placeholder={"Count"}
                    setValue={(e) => setCount(e.target.value)}
                  ></InputComponent>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleClick}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </form>
          {/* <form>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <div className="sm:col-span-3">
                     
                    </div>

                    <div className="sm:col-span-3">
                     
                    </div>

                    <div className="sm:col-span-3">
                    
                    </div>

                    <div className="sm:col-span-2">
                     
                    </div>

                    <div className="sm:col-span-2">
                     
                    </div>

                    <div className="sm:col-span-2">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleClick}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </form> */}
        </div>
      </div>
    </div>
  );
};

export default AddTrade;
