import React, { useEffect, useRef, useCallback } from "react";
import { EDITOR_JS_TOOLS } from "./EditorJsTools";
import { createReactEditorJS } from "react-editor-js";

const EditorComponent = ({ editorjsLabel, childToParent, selectedValue }) => {
  const editorCore = useRef(null);
  const ReactEditorJS = createReactEditorJS();

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = useCallback(async () => {
    const savedData = await editorCore.current.save();
    childToParent(savedData);
  }, [childToParent]);

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {editorjsLabel}
      </label>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg border rounded-md">
        <ReactEditorJS
          key={selectedValue ? "editor-initialized" : "editor-loading"}
          data={selectedValue || {}}
          onInitialize={handleInitialize}
          onChange={handleSave}
          tools={EDITOR_JS_TOOLS}
        />
      </div>
    </>
  );
};

export default EditorComponent;
