import {
  GET_PRODUCT,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_ERROR,
  POST_PRODUCT_PHOTO,
  POST_PRODUCT_PHOTO_ERROR,
  POST_NEW_PRODUCT,
  POST_NEW_PRODUCT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  POST_PRODUCT_MULTIPLE_PHOTO,
  POST_PRODUCT_MULTIPLE_PHOTO_ERROR,
  POST_PRODUCT_COUNT,
  POST_PRODUCT_COUNT_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
} from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const getProductDetails = (subCat, prodId) => {
  return (dispatch) => {
    axios
      .post(
        api + "Product/admin/product-detail",
        {
          subCategoryId: subCat,
          productId: prodId,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_DETAIL,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_PRODUCT_DETAIL_ERROR,
          payload: error,
        });
      });
  };
};

export const postProductFile = (data) => {
  return (dispatch) => {
    axios
      .post(api + "Product/admin/add-single-image", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: POST_PRODUCT_PHOTO,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: POST_PRODUCT_PHOTO_ERROR,
          payload: error,
        });
      });
  };
};

export const postMultiProductFile = (data) => {
  return (dispatch) => {
    axios
      .post(api + "Product/admin/add-multiple-image", data, {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      })
      .then((response) => {
        dispatch({
          type: POST_PRODUCT_MULTIPLE_PHOTO,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: POST_PRODUCT_MULTIPLE_PHOTO_ERROR,
          payload: error,
        });
      });
  };
};

export const postNewProduct = (data) => {
  return (dispatch) => {
    axios
      .post(api + "Product/admin/add-product", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: POST_NEW_PRODUCT,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: POST_NEW_PRODUCT_ERROR,
          payload: error,
        });
      });
  };
};

export const postProductCount = (data) => {
  return (dispatch) => {
    axios
      .post(api + "Product/admin/add-product-count", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: POST_PRODUCT_COUNT,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: POST_PRODUCT_COUNT_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteProduct = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Product/admin/remove-product/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_PRODUCT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PRODUCT_ERROR,
          payload: error,
        });
      });
  };
};

export const updateProduct = (data) => (dispatch) =>
  axios
    .put(`${api}Product/admin/update-product`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: UPDATE_PRODUCT,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: UPDATE_PRODUCT_ERROR,
        payload: error,
      });
    });
