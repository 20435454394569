import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function MultiSelectBox({ options, selectedValues, onChange }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (selectedValues) {
      const newSelectedOptions = selectedValues
        .map((value) =>
          options?.find(
            (option) => option.id === value || option.classId === value
          )
        )
        .filter((option) => option != null); // null olmayan seçenekleri filtrele
      setSelectedOptions(newSelectedOptions);
    }
  }, [options]);

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions.map((option) => option.id || option.classId));
  };

  return (
    <Select
      isMulti
      options={options}
      getOptionLabel={(option) =>
        option.originalName ||
        option.originalType ||
        option.region ||
        option.organization
      }
      getOptionValue={(option) => option.id || option.classId}
      value={selectedOptions}
      onChange={handleChange}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  );
}
