import {
  POST_NEW_MAIN_AMMUNITION_CATEGORY,
  POST_NEW_MAIN_AMMUNITION_CATEGORY_ERROR,
  POST_NEW_SUB_AMMUNITION_CATEGORY,
  POST_NEW_SUB_AMMUNITION_CATEGORY_ERROR,
  GET_MAIN_AMMUNITION_CATEGORY,
  GET_MAIN_AMMUNITION_CATEGORY_ERROR,
  GET_SUB_AMMUNITION_CATEGORY,
  GET_SUB_AMMUNITION_CATEGORY_ERROR,
  DELETE_MAIN_AMMUNITION_CATEGORY,
  DELETE_MAIN_AMMUNITION_CATEGORY_ERROR,
  DELETE_SUB_AMMUNITION_CATEGORY,
  DELETE_SUB_AMMUNITION_CATEGORY_ERROR,
  GET_MUNITION,
  GET_MUNITION_ERROR,
  POST_NEW_MUNITION,
  POST_NEW_MUNITION_ERROR,
  GET_MUNITION_PROPS,
  GET_MUNITION_PROPS_ERROR,
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_COMPANY,
  GET_COMPANY_ERROR,
  DELETE_MUNITION,
  DELETE_MUNITION_ERROR,
  UPDATE_MAIN_AMMUNITION_CATEGORY,
  UPDATE_MAIN_AMMUNITION_CATEGORY_ERROR,
  GET_SUB_AMMUNITION_CATEGORY_V2,
  GET_SUB_AMMUNITION_CATEGORY_ERROR_V2,
  UPDATE_SUB_AMMUNITION_CATEGORY,
  UPDATE_SUB_AMMUNITION_CATEGORY_ERROR,
  UPDATE_AMMUNITION,
  UPDATE_AMMUNITION_ERROR,
} from "../types";

const initialState = {
  mainAmmunitionCategories: [],
  subAmmunitionCategories: [],
  munitions: [],
  munitionProps: [],
  countries: [],
  companies: [],
  subAmmunitionCategoriesV2: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_MUNITION:
      return {
        ...state,
        loading: false,
      };

    case DELETE_MUNITION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_COMPANY:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };

    case GET_COMPANY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        loading: false,
      };

    case GET_COUNTRIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_MUNITION_PROPS:
      return {
        ...state,
        munitionProps: action.payload,
        loading: false,
      };

    case GET_MUNITION_PROPS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_MUNITION:
      return {
        ...state,
        munitions: action.payload,
        loading: false,
      };

    case GET_MUNITION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_MUNITION:
      return {
        ...state,
        loading: false,
      };

    case POST_NEW_MUNITION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_MAIN_AMMUNITION_CATEGORY:
      return {
        ...state,
        loading: false,
      };

    case POST_NEW_MAIN_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case UPDATE_MAIN_AMMUNITION_CATEGORY:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_MAIN_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case UPDATE_AMMUNITION:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_AMMUNITION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case UPDATE_SUB_AMMUNITION_CATEGORY:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_SUB_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_SUB_AMMUNITION_CATEGORY:
      return {
        ...state,
        loading: false,
      };

    case POST_NEW_SUB_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_MAIN_AMMUNITION_CATEGORY:
      return {
        ...state,
        loading: false,
      };

    case DELETE_MAIN_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_SUB_AMMUNITION_CATEGORY:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SUB_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_MAIN_AMMUNITION_CATEGORY:
      return {
        ...state,
        mainAmmunitionCategories: action.payload,
        loading: false,
      };

    case GET_MAIN_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_SUB_AMMUNITION_CATEGORY:
      return {
        ...state,
        subAmmunitionCategories: action.payload,
        loading: false,
      };

    case GET_SUB_AMMUNITION_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_SUB_AMMUNITION_CATEGORY_V2:
      return {
        ...state,
        subAmmunitionCategoriesV2: action.payload,
        loading: false,
      };

    case GET_SUB_AMMUNITION_CATEGORY_ERROR_V2:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return initialState;
  }
}
