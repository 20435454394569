export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const ADD_USERS = "ADD_USERS";
export const ADD_USERS_ERROR = "ADD_USERS_ERROR";

export const EDIT_USERS = "EDIT_USERS";
export const EDIT_USERS_ERROR = "EDIT_USERS_ERROR";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const GET_USER_LOG = "GET_USER_LOG";
export const GET_USER_LOG_ERROR = "GET_USER_LOG_ERROR";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_COUNTRY_ERROR = "UPDATE_COUNTRY_ERROR";

export const GET_REGIONS = "GET_REGIONS";
export const GET_REGIONS_ERROR = "GET_REGIONS_ERROR";

export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";

export const GET_COUNTRY_PROPS = "GET_COUNTRY_PROPS";
export const GET_COUNTRY_PROPS_ERROR = "GET_COUNTRY_PROPS_ERROR";

export const GET_CONTINENTS = "GET_CONTINENTS";
export const GET_CONTINENTS_ERROR = "GET_CONTINENTS_ERROR";

export const GET_FORM_OF_GOVERMENT = "GET_FORM_OF_GOVERMENT";
export const GET_FORM_OF_GOVERMENT_ERROR = "GET_FORM_OF_GOVERMENT_ERROR";

export const GET_COUNTRIES_DETAIL = "GET_COUNTRIES_DETAIL";
export const GET_COUNTRIES_DETAIL_ERROR = "GET_COUNTRIES_DETAIL_ERROR";

export const GET_SUB_CATEGORY_DETAIL = "GET_SUB_CATEGORY_DETAIL";
export const GET_SUB_CATEGORY_DETAIL_ERROR = "GET_SUB_CATEGORY_DETAIL_ERROR";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const UPDATE_CATEGORIES_ERROR = "UPDATE_CATEGORIES_ERROR";

export const UPDATE_SUB_CATEGORIES = "UPDATE_SUB_CATEGORIES";
export const UPDATE_SUB_CATEGORIES_ERROR = "UPDATE_CATEGORIES_SUB__ERROR";

export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const DELETE_CATEGORIES_ERROR = "DELETE_CATEGORIES_ERROR";

export const DELETE_SUB_CATEGORIES = "DELETE_SUB_CATEGORIES";
export const DELETE_SUB_CATEGORIES_ERROR = "DELETE_SUB_CATEGORIES_ERROR";

export const POST_NEW_CATEGORIES = "POST_NEW_CATEGORIES";
export const POST_NEW_CATEGORIES_ERROR = "POST_NEW_CATEGORIES_ERROR";

export const POST_NEW_SUB_CATEGORIES = "POST_NEW_SUB_CATEGORIES";
export const POST_NEW_SUB_CATEGORIES_ERROR = "POST_NEW_SUB_CATEGORIES_ERROR";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";

export const POST_NEW_PRODUCT = "POST_NEW_PRODUCT";
export const POST_NEW_PRODUCT_ERROR = "POST_NEW_PRODUCT_ERROR";

export const POST_PRODUCT_COUNT = "POST_PRODUCT_COUNT";
export const POST_PRODUCT_COUNT_ERROR = "POST_PRODUCT_COUNT_ERROR";

export const POST_PRODUCT_PHOTO = "POST_PRODUCT_PHOTO";
export const POST_PRODUCT_PHOTO_ERROR = "POST_PRODUCT_PHOTO_ERROR";

export const POST_PRODUCT_MULTIPLE_PHOTO = "POST_PRODUCT_MULTIPLE_PHOTO";
export const POST_PRODUCT_MULTIPLE_PHOTO_ERROR =
  "POST_PRODUCT_MULTIPLE_PHOTO_ERROR";

export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";
export const GET_PRODUCT_DETAIL_ERROR = "GET_PRODUCT_DETAIL_ERROR";

export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_ERROR = "GET_REPORTS_ERROR";

export const UPDATE_REPORTS = "UPDATE_REPORTS";
export const UPDATE_REPORTS_ERROR = "UPDATE_REPORTS_ERROR";

export const GET_REPORTS_TYPE = "GET_REPORTS_TYPE";
export const GET_REPORTS_TYPE_ERROR = "GET_REPORTS_TYPE_ERROR";

export const POST_NEW_REPORTS = "POST_NEW_REPORTS";
export const POST_NEW_REPORTS_ERROR = "POST_NEW_REPORTS_ERROR";

export const DELETE_REPORTS = "DELETE_REPORTS";
export const DELETE_REPORTS_ERROR = "DELETE_REPORTS_ERROR";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";

export const POST_NEW_COMPANY = "POST_NEW_COMPANY";
export const POST_NEW_COMPANY_ERROR = "POST_NEW_COMPANY_ERROR";

export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const GET_COMPANY_DETAIL_ERROR = "GET_COMPANY_DETAIL_ERROR";

export const GET_SUB_CATEGORIES_BY_CATEGORIES =
  "GET_SUB_CATEGORIES_BY_CATEGORIES";
export const GET_SUB_CATEGORIES_BY_CATEGORIES_ERROR =
  "GET_SUB_CATEGORIES_BY_CATEGORIES_ERROR";

export const GET_SUB_CATEGORY_PROPS = "GET_SUB_CATEGORY_PROPS";
export const GET_SUB_CATEGORY_PROPS_ERROR = "GET_SUB_CATEGORY_PROPS_ERROR";

export const GET_SUB_CATEGORY_SELECTBOX_DATA =
  "GET_SUB_CATEGORY_SELECTBOX_DATA";
export const GET_SUB_CATEGORY_SELECTBOX_DATA_ERROR =
  "GET_SUB_CATEGORY_SELECTBOX_DATA_ERROR";

export const GET_UPDATES = "GET_UPDATES";
export const GET_UPDATES_ERROR = "GET_UPDATES_ERROR";

export const GET_UPDATE_DETAIL = "GET_UPDATE_DETAIL";
export const GET_UPDATE_DETAIL_ERROR = "GET_UPDATE_DETAIL_ERROR";

export const REFRESH_UPDATE = "REFRESH_UPDATE";
export const REFRESH_UPDATE_ERROR = "REFRESH_UPDATE_ERROR";

export const DELETE_UPDATE = "DELETE_UPDATE";
export const DELETE_UPDATE_ERROR = "DELETE_UPDATE_ERROR";

export const GET_UPDATES_TYPE = "GET_UPDATES_TYPE";
export const GET_UPDATES_TYPE_ERROR = "GET_UPDATES_TYPE_ERROR";

export const POST_NEW_UPDATES = "POST_NEW_UPDATES";
export const POST_NEW_UPDATES_ERROR = "POST_NEW_UPDATES_ERROR";

export const GET_MAIN_AMMUNITION_CATEGORY = "GET_MAIN_AMMUNITION_CATEGORY";
export const GET_MAIN_AMMUNITION_CATEGORY_ERROR =
  "GET_MAIN_AMMUNITION_CATEGORY_ERROR";

export const GET_MUNITION = "GET_MUNITION";
export const GET_MUNITION_ERROR = "GET_MUNITION_ERROR";

export const DELETE_MUNITION = "DELETE_MUNITION";
export const DELETE_MUNITION_ERROR = "DELETE_MUNITION_ERROR";

export const GET_MUNITION_PROPS = "GET_MUNITION_PROPS";
export const GET_MUNITION_PROPS_ERROR = "GET_MUNITION_PROPS_ERROR";

export const POST_NEW_MUNITION = "POST_NEW_MUNITION";
export const POST_NEW_MUNITION_ERROR = "POST_NEW_MUNITION_ERROR";

export const UPDATE_AMMUNITION = "UPDATE_AMMUNITION";
export const UPDATE_AMMUNITION_ERROR = "UPDATE_AMMUNITION_ERROR";

export const GET_SUB_AMMUNITION_CATEGORY = "GET_SUB_AMMUNITION_CATEGORY";
export const GET_SUB_AMMUNITION_CATEGORY_ERROR =
  "GET_SUB_AMMUNITION_CATEGORY_ERROR";

export const GET_SUB_AMMUNITION_CATEGORY_V2 = "GET_SUB_AMMUNITION_CATEGORY_V2";
export const GET_SUB_AMMUNITION_CATEGORY_ERROR_V2 =
  "GET_SUB_AMMUNITION_CATEGORY_ERROR_V2";

export const POST_NEW_MAIN_AMMUNITION_CATEGORY =
  "POST_NEW_MAIN_AMMUNITION_CATEGORY";
export const POST_NEW_MAIN_AMMUNITION_CATEGORY_ERROR =
  "POST_NEW_MAIN_AMMUNITION_CATEGORY_ERROR";

export const UPDATE_MAIN_AMMUNITION_CATEGORY =
  "UPDATE_MAIN_AMMUNITION_CATEGORY";
export const UPDATE_MAIN_AMMUNITION_CATEGORY_ERROR =
  "UPDATE_MAIN_AMMUNITION_CATEGORY_ERROR";

export const UPDATE_SUB_AMMUNITION_CATEGORY = "UPDATE_SUB_AMMUNITION_CATEGORY";
export const UPDATE_SUB_AMMUNITION_CATEGORY_ERROR =
  "UPDATE_SUB_AMMUNITION_CATEGORY_ERROR";

export const POST_NEW_SUB_AMMUNITION_CATEGORY =
  "POST_NEW_SUB_AMMUNITION_CATEGORY";
export const POST_NEW_SUB_AMMUNITION_CATEGORY_ERROR =
  "POST_NEW_SUB_AMMUNITION_CATEGORY_ERROR";

export const DELETE_MAIN_AMMUNITION_CATEGORY =
  "DELETE_MAIN_AMMUNITION_CATEGORY";
export const DELETE_MAIN_AMMUNITION_CATEGORY_ERROR =
  "DELETE_MAIN_AMMUNITION_CATEGORY_ERROR";

export const DELETE_SUB_AMMUNITION_CATEGORY = "DELETE_SUB_AMMUNITION_CATEGORY";
export const DELETE_SUB_AMMUNITION_CATEGORY_ERROR =
  "DELETE_SUB_AMMUNITION_CATEGORY_ERROR";
