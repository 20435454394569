import {
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_SUB_CATEGORIES_BY_CATEGORIES,
  GET_SUB_CATEGORIES_BY_CATEGORIES_ERROR,
  GET_SUB_CATEGORY_PROPS,
  GET_SUB_CATEGORY_PROPS_ERROR,
  GET_SUB_CATEGORY_SELECTBOX_DATA,
  GET_SUB_CATEGORY_SELECTBOX_DATA_ERROR,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_ERROR,
  GET_PRODUCT,
  GET_PRODUCT_ERROR,
  POST_NEW_CATEGORIES,
  POST_NEW_CATEGORIES_ERROR,
  POST_NEW_SUB_CATEGORIES,
  POST_NEW_SUB_CATEGORIES_ERROR,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_ERROR,
  DELETE_SUB_CATEGORIES,
  DELETE_SUB_CATEGORIES_ERROR,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_ERROR,
  UPDATE_SUB_CATEGORIES,
  UPDATE_SUB_CATEGORIES_ERROR,
  GET_SUB_CATEGORY_DETAIL,
  GET_SUB_CATEGORY_DETAIL_ERROR,
} from "../types";

const initialState = {
  products: [],
  subCategoryDetail: [],
  categories: [],
  subCategoriesByCategory: [],
  subCategoryProps: [],
  productDetail: [],
  subCategorySelectboxData: [],
  postMainCategory: [],
  postSubCategory: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };

    case GET_PRODUCT_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_SUB_CATEGORY_DETAIL:
      return {
        ...state,
        subCategoryDetail: action.payload,
        loading: false,
      };

    case GET_SUB_CATEGORY_DETAIL_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case DELETE_CATEGORIES:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case UPDATE_CATEGORIES:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case UPDATE_SUB_CATEGORIES:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_SUB_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case DELETE_SUB_CATEGORIES:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SUB_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };

    case GET_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_SUB_CATEGORIES_BY_CATEGORIES:
      return {
        ...state,
        subCategoriesByCategory: action.payload,
        loading: false,
      };

    case GET_SUB_CATEGORIES_BY_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_SUB_CATEGORY_PROPS:
      return {
        ...state,
        subCategoryProps: action.payload,
        loading: false,
      };

    case GET_SUB_CATEGORY_PROPS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_SUB_CATEGORY_SELECTBOX_DATA:
      return {
        ...state,
        subCategorySelectboxData: action.payload,
        loading: false,
      };

    case GET_SUB_CATEGORY_SELECTBOX_DATA_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: action.payload,
        loading: false,
      };

    case GET_PRODUCT_DETAIL_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_CATEGORIES:
      return {
        ...state,
        postMainCategory: action.payload,
        loading: false,
      };

    case POST_NEW_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_SUB_CATEGORIES:
      return {
        ...state,
        postSubCategory: action.payload,
        loading: false,
      };

    case POST_NEW_SUB_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return initialState;
  }
}
