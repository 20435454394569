import {
  GET_UPDATES_TYPE,
  GET_UPDATES_TYPE_ERROR,
  POST_NEW_UPDATES,
  POST_NEW_UPDATES_ERROR,
  GET_UPDATES,
  GET_UPDATES_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_UPDATE_DETAIL,
  GET_UPDATE_DETAIL_ERROR,
  REFRESH_UPDATE,
  REFRESH_UPDATE_ERROR,
  DELETE_UPDATE,
  DELETE_UPDATE_ERROR,
} from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const getUpdatesType = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Update/update-types", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_UPDATES_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UPDATES_TYPE_ERROR,
      payload: error,
    });
  }
};

export const addUpdate = (data) => (dispatch) =>
  axios
    .post(`${api}Update/admin/add-update`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: POST_NEW_UPDATES,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: POST_NEW_UPDATES_ERROR,
        payload: error,
      });
    });

export const getUpdates = (data) => {
  return (dispatch) => {
    axios
      .get(api + "Update/updates-main?mainId=" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_UPDATES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_UPDATES_ERROR,
          payload: error,
        });
      });
  };
};

export const getCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Product/categories", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CATEGORIES_ERROR,
      payload: error,
    });
  }
};

export const getUpdateDetail = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      api + "Update/updates?updateId=" + data,
      [],
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: GET_UPDATE_DETAIL,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_UPDATE_DETAIL_ERROR,
      payload: error,
    });
  }
};

export const refreshUpdate = (data) => (dispatch) =>
  axios
    .put(`${api}Update/admin/refresh-update`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: REFRESH_UPDATE,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: REFRESH_UPDATE_ERROR,
        payload: error,
      });
    });

export const deleteUpdate = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Update/admin/remove-update/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_UPDATE,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: DELETE_UPDATE_ERROR,
          payload: error,
        });
      });
  };
};
