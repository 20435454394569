import {
  HomeIcon,
  ShieldExclamationIcon,
  PresentationChartBarIcon,
  ScaleIcon,
  BuildingOffice2Icon,
  PencilSquareIcon,
  BookOpenIcon,
  GlobeAsiaAustraliaIcon,
  CubeIcon,
  UsersIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Home", href: "/Home", icon: HomeIcon, current: true },
  {
    name: "Categories",
    icon: BookOpenIcon,
    current: false,
    children: [
      { name: "Add Categories", href: "/AddCategories" },
      { name: "List Categories", href: "/ListCategories" },
      { name: "Add Sub Categories", href: "/AddSubCategories" },
      { name: "List Sub Categories", href: "/ListSubCategories" },
    ],
  },
  {
    name: "Country",
    icon: GlobeAsiaAustraliaIcon,
    current: false,
    children: [{ name: "List Country", href: "/ListCountry" }],
  },
  {
    name: "Products",
    icon: CubeIcon,
    current: false,
    children: [
      { name: "Add Products", href: "/AddProduct" },
      { name: "List Products", href: "/ListProduct" },
      { name: "Add Update", href: "/AddUpdate" },
      { name: "List Update", href: "/ListUpdate" },
      //{ name: "Add Products Count", href: "/AddProductCount" },
      //{ name: "Add Additional Picture", href: "/AddAdditionalPicture" },
    ],
  },
  {
    name: "Companies",
    icon: BuildingOffice2Icon,
    current: false,
    children: [
      { name: "Add Company", href: "/AddCompany" },
      { name: "List Companies", href: "/ListCompany" },
    ],
  },
  {
    name: "Arms Trade",
    icon: ScaleIcon,
    current: false,
    children: [
      { name: "Add Trade", href: "/AddTrade" },
      { name: "List Trades", href: "/ListTrade" },
    ],
  },
  {
    name: "Ammunition",
    icon: ShieldExclamationIcon,
    current: false,
    children: [
      { name: "Add Ammunition", href: "/AddAmmunition" },
      { name: "List Ammunition", href: "/ListAmmunition" },
      { name: "Add Main Ammunition", href: "/AddMainAmmunition" },
      { name: "List Main Ammunition", href: "/ListMainAmmunition" },
      { name: "Add Sub Ammunition", href: "/AddSubAmmunition" },
      { name: "List Sub Ammunition", href: "/ListSubAmmunition" },
    ],
  },

  {
    name: "Reports",
    icon: PresentationChartBarIcon,
    current: false,
    children: [
      { name: "Add Reports", href: "/AddReport" },
      { name: "List Reports", href: "/ListReport" },
    ],
  },
  // {
  //   name: "Members",
  //   icon: UsersIcon,
  //   current: false,
  //   children: [
  //     { name: "Add Members", href: "/AddMember" },
  //     { name: "List Members", href: "/ListMember" },
  //     { name: "Member Logs", href: "/LogMember" },
  //   ],
  // },

  // {
  //   name: "Messages",
  //   icon: PencilSquareIcon,
  //   current: false,
  //   children: [
  //     { name: "Text a Message", href: "/SendMessage" },
  //     { name: "List Message", href: "/ListMessage" },
  //     { name: "Product Messages", href: "/ListProductMessage" },
  //     { name: "Contact Form Messages", href: "/ListContactFormMessage" },
  //   ],
  // },
  {
    name: "Users",
    icon: UserCircleIcon,
    current: false,
    children: [
      { name: "Add Users", href: "/AddUser" },
      { name: "List Users", href: "/ListUser" },
      { name: "Logs", href: "/LogUser" },
    ],
  },
];

export default navigation;
