import React from "react";
import { Route, Routes } from "react-router-dom";

import AddCategories from "../Views/Categories/Adding/AddCategories";
import AddSubCategories from "../Views/Categories/Adding/AddSubCategories";
import ListCategories from "../Views/Categories/Listing/ListCategories";
import ListSubCategories from "../Views/Categories/Listing/ListSubCategories";
import EditCategory from "../Views/Categories/Editing/EditCategory";
import EditSubCategory from "../Views/Categories/Editing/EditSubCategory";

import ListCountry from "../Views/Country/Listing/ListCountry";
import EditCountry from "../Views/Country/Adding/EditCountry";

import AddProduct from "../Views/Products/Adding/AddProduct";
import AddUpdate from "../Views/Products/Adding/AddUpdate";
//import AddProductCount from "../Views/Products/Adding/AddProductCount";
//import AddAdditionalPicture from "../Views/Products/Adding/AddAdditionalPicture";
import ListProduct from "../Views/Products/Listing/ListProduct";
import ListUpdate from "../Views/Products/Listing/ListUpdate";
import EditProduct from "../Views/Products/Editing/EditProduct";
import EditUpdate from "../Views/Products/Editing/EditUpdate";

import AddCompany from "../Views/Companies/Adding/AddCompany";
import ListCompany from "../Views/Companies/Listing/ListCompany";
import EditCompany from "../Views/Companies/Editing/EditCompany";

import AddTrade from "../Views/Arms Trade/Adding/AddTrade";
import ListTrade from "../Views/Arms Trade/Listing/ListTrade";

import AddAmmunition from "../Views/Ammunition/Adding/AddAmmunition";
import ListAmmunition from "../Views/Ammunition/Listing/ListAmmunition";
import EditAmmunition from "../Views/Ammunition/Editing/EditAmmunition";
import AddMainAmmunition from "../Views/Ammunition/Adding/AddMainAmmunition";
import ListMainAmmunition from "../Views/Ammunition/Listing/ListMainAmmunition";
import EditMainAmmunition from "../Views/Ammunition/Editing/EditMainAmmunition";
import AddSubAmmunition from "../Views/Ammunition/Adding/AddSubAmmunition";
import ListSubAmmunition from "../Views/Ammunition/Listing/ListSubAmmunition";
import EditSubAmmunition from "../Views/Ammunition/Editing/EditSubAmmunition";

// import AddFeature from "../Views/Features/Adding/AddFeature";
// import ListFeature from "../Views/Features/Listing/ListFeature";

import AddReport from "../Views/Reports/Adding/AddReport";
import ListReport from "../Views/Reports/Listing/ListReport";
import EditReport from "../Views/Reports/Editing/EditReport";

// import AddMember from "../Views/Members/Adding/AddMember";
// import ListMember from "../Views/Members/Listing/ListMember";
// import LogMember from "../Views/Members/Logging/LogMember";

// import SendMessage from "../Views/Messages/Adding/SendMessage";
// import ListMessage from "../Views/Messages/Listing/ListMessage";
// import ListContactFormMessage from "../Views/Messages/Listing/ListContactFormMessage";
// import ListProductMessage from "../Views/Messages/Listing/ListProductMessage";

import AddUser from "../Views/Users/Adding/AddUser";
import ListUser from "../Views/Users/Listing/ListUser";
import LogUser from "../Views/Users/Logging/LogUser";
import EditUser from "../Views/Users/Editing/EditUser";

import Home from "../Views/Home/Home";
import Login from "../Views/Login/Login";

export const Router = () => {
  return (
    <div>
      <Routes>
        <Route path="/Home" exact element={<Home />}></Route>
        <Route path="/Login" exact element={<Login />}></Route>
        {/* ------------Categories----------------- */}
        <Route path="/AddCategories" exact element={<AddCategories />}></Route>
        <Route
          path="/ListCategories"
          exact
          element={<ListCategories />}
        ></Route>
        <Route
          path="/AddSubCategories"
          exact
          element={<AddSubCategories />}
        ></Route>
        <Route
          path="/ListSubCategories"
          exact
          element={<ListSubCategories />}
        ></Route>
        <Route path="/EditCategory" exact element={<EditCategory />}></Route>
        <Route
          path="/EditSubCategory"
          exact
          element={<EditSubCategory />}
        ></Route>

        {/* ------------Country----------------- */}

        <Route path="/ListCountry" exact element={<ListCountry />}></Route>
        <Route path="/EditCountry" exact element={<EditCountry />}></Route>

        {/* ------------Product----------------- */}
        <Route path="/AddProduct" exact element={<AddProduct />}></Route>
        <Route path="/EditProduct" exact element={<EditProduct />}></Route>
        <Route path="/AddUpdate" exact element={<AddUpdate />}></Route>
        <Route path="/EditUpdate" exact element={<EditUpdate />}></Route>
        {/* <Route
          path="/AddProductCount"
          exact
          element={<AddProductCount />}
        ></Route>
        <Route
          path="/AddAdditionalPicture"
          exact
          element={<AddAdditionalPicture />}
        ></Route> */}
        <Route path="/ListProduct" exact element={<ListProduct />}></Route>
        <Route path="/ListUpdate" exact element={<ListUpdate />}></Route>

        {/* ------------Companies----------------- */}
        <Route path="/AddCompany" exact element={<AddCompany />}></Route>
        <Route path="/ListCompany" exact element={<ListCompany />}></Route>
        <Route path="/EditCompany" exact element={<EditCompany />}></Route>

        {/* ------------ArmsTrade----------------- */}
        <Route path="/AddTrade" exact element={<AddTrade />}></Route>
        <Route path="/ListTrade" exact element={<ListTrade />}></Route>

        {/* ------------Ammunition----------------- */}
        <Route path="/AddAmmunition" exact element={<AddAmmunition />}></Route>
        <Route
          path="/EditAmmunition"
          exact
          element={<EditAmmunition />}
        ></Route>
        <Route
          path="/ListAmmunition"
          exact
          element={<ListAmmunition />}
        ></Route>
        <Route
          path="/AddMainAmmunition"
          exact
          element={<AddMainAmmunition />}
        ></Route>
        <Route
          path="/EditMainAmmunition"
          exact
          element={<EditMainAmmunition />}
        ></Route>
        <Route
          path="/ListMainAmmunition"
          exact
          element={<ListMainAmmunition />}
        ></Route>
        <Route
          path="/AddSubAmmunition"
          exact
          element={<AddSubAmmunition />}
        ></Route>
        <Route
          path="/ListSubAmmunition"
          exact
          element={<ListSubAmmunition />}
        ></Route>
        <Route
          path="/EditSubAmmunition"
          exact
          element={<EditSubAmmunition />}
        ></Route>

        {/* ------------Features-----------------
        <Route path="/AddFeature" exact element={<AddFeature />}></Route>
        <Route path="/ListFeature" exact element={<ListFeature />}></Route> */}

        {/* ------------Reports----------------- */}
        <Route path="/AddReport" exact element={<AddReport />}></Route>
        <Route path="/ListReport" exact element={<ListReport />}></Route>
        <Route path="/EditReport" exact element={<EditReport />}></Route>

        {/* ------------Members-----------------
        <Route path="/AddMember" exact element={<AddMember />}></Route>
        <Route path="/ListMember" exact element={<ListMember />}></Route>
        <Route path="/LogMember" exact element={<LogMember />}></Route> */}

        {/* ------------Messages-----------------
        <Route path="/SendMessage" exact element={<SendMessage />}></Route>
        <Route path="/ListMessage" exact element={<ListMessage />}></Route>
        <Route
          path="/ListContactFormMessage"
          exact
          element={<ListContactFormMessage />}
        ></Route>
        <Route
          path="/ListProductMessage"
          exact
          element={<ListProductMessage />}
        ></Route> */}

        {/* ------------Users----------------- */}
        <Route path="/AddUser" exact element={<AddUser />}></Route>
        <Route path="/ListUser" exact element={<ListUser />}></Route>
        <Route path="/LogUser" exact element={<LogUser />}></Route>
        <Route path="/EditUser" exact element={<EditUser />}></Route>
      </Routes>
    </div>
  );
};
