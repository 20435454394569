import {
  GET_UPDATES_TYPE,
  GET_UPDATES_TYPE_ERROR,
  POST_NEW_UPDATES,
  POST_NEW_UPDATES_ERROR,
  GET_UPDATES,
  GET_UPDATES_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_UPDATE_DETAIL,
  GET_UPDATE_DETAIL_ERROR,
  REFRESH_UPDATE,
  REFRESH_UPDATE_ERROR,
  DELETE_UPDATE,
  DELETE_UPDATE_ERROR,
} from "../types";

const initialState = {
  updateTypes: [],
  updates: [],
  loading: true,
  categories: [],
  updateDetail: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_UPDATE:
      return {
        ...state,
        loading: false,
      };

    case DELETE_UPDATE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_UPDATE_DETAIL:
      return {
        ...state,
        updateDetail: action.payload,
        loading: false,
      };

    case GET_UPDATE_DETAIL_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };

    case GET_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_UPDATES:
      return {
        ...state,
        updates: action.payload,
        loading: false,
      };

    case GET_UPDATES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_UPDATES_TYPE:
      return {
        ...state,
        updateTypes: action.payload,
        loading: false,
      };

    case GET_UPDATES_TYPE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_UPDATES:
      return {
        ...state,
        loading: false,
      };

    case POST_NEW_UPDATES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case REFRESH_UPDATE:
      return {
        ...state,
        loading: false,
      };

    case REFRESH_UPDATE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
