import React, { useState, useEffect } from "react";
import InputComponent from "../../../Components/Input";
//import SelectComponent from "../../../Components/Selectbox";
import PhotoUpload from "../../../Components/PhotoUpload";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCompaniesDetail,
  updateCompany,
} from "../../../Store/actions/CompanyAction";
import { postProductFile } from "../../../Store/actions/ProductAction";
import SwitchComponent from "../../../Components/Switch";

const EditCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState([]);
  const [newPhoto, setNewPhoto] = useState([]);
  const [photoLink, setPhotoLink] = useState([]);
  const [sendData, setSendData] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [checkedData, setCheckedData] = useState(false);
  const [dynamicSwitchData, setDynamicSwitchData] = useState([]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const companiesListData = useSelector((state) => state.companyList);
  const { loading, error, companiesDetail } = companiesListData;

  const handleClick = async () => {
    const formDataArray = [];
    const photoDataArray = [];

    for (let getFile of photo) {
      const formData = new FormData();
      const file = getFile;
      const fileName = getFile.name;

      formData.append("file", file);
      formData.append("fileName", fileName);
      formDataArray.push({ formData, fileName });
    }

    await Promise.all(
      formDataArray.map(({ formData }) => dispatch(postProductFile(formData)))
    );

    formDataArray.forEach(({ fileName }) => {
      photoDataArray.push(fileName);
    });

    let switchKeys = Object.keys(dynamicSwitchData);
    let switchValues = Object.values(dynamicSwitchData);

    let status = "";

    let statusCheck = switchKeys.includes("status");

    status = statusCheck && switchValues[switchKeys.indexOf("status")];

    setSendData((prevSendingData) => ({
      ...prevSendingData,
      id: companiesDetail.id,
      originalName: name === "" ? companiesDetail.originalName : name,
      address: address === "" ? companiesDetail.address : address,
      contactPhoneNumber:
        phoneNumber === "" ? companiesDetail.contactPhoneNumber : phoneNumber,
      email: email === "" ? companiesDetail.email : email,
      website: website === "" ? companiesDetail.website : website,
      image: [
        ...photoLink.map((x) => ({
          url: x,
        })),
        ...photoDataArray.map((fileName) => ({
          url: "https://cdn.mildata.net/data/" + fileName,
        })),
      ],
      status: status,
    }));

    setSuccessModal(true);
  };

  const successModalButton = (e) => {
    if (e == "save") {
      setSuccessModal(false);
      console.log(sendData);
      dispatch(updateCompany(sendData));
      //window.location.reload(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  const getImageData = async (data) => {
    if (data.target.files != null) {
      const fileList = data.target.files;
      const fileArray = Array.from(fileList);

      setPhoto((prevPhotos) => [...prevPhotos, ...fileArray]);

      setNewPhoto((prevPhotos) => [
        ...prevPhotos,
        ...fileArray.map((file) => URL.createObjectURL(file)),
      ]);
    }
  };

  const deleteFile = (e) => {
    const updatedImages = [...photoLink, ...newPhoto];
    updatedImages.splice(e, 1);
    setPhotoLink(updatedImages);
    setNewPhoto([]);
  };

  const getDynamicSwitchData = (e) => {
    setDynamicSwitchData({
      ...dynamicSwitchData,
      ["status"]: e,
    });
  };

  useEffect(() => {
    let getQueryData = query.get("id");

    if (getQueryData != null) {
      dispatch(getCompaniesDetail(getQueryData));
      setIsFormValid(true);
      validateUpdateForm();

      setPhotoLink((prevData) =>
        companiesDetail && companiesDetail.imageUrl
          ? [companiesDetail.imageUrl]
          : []
      );
    }
  }, [companiesDetail]);

  const validateUpdateForm = () => {
    const isPhotoValid = photo.length > 0;

    setIsFormValid(isPhotoValid);
  };

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Edit Company
          </h2>
        </div>
        <div className="px-4 py-5 sm:p-6 ">
          <form className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Name
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"name"}
                    id={"name"}
                    selectedValue={
                      companiesDetail && companiesDetail.originalName
                    }
                    placeholder={"Name"}
                    setValue={(e) => setName(e.target.value)}
                  ></InputComponent>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Address
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"address"}
                    id={"address"}
                    selectedValue={companiesDetail && companiesDetail.address}
                    placeholder={"Address"}
                    setValue={(e) => setAddress(e.target.value)}
                  ></InputComponent>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Phone Number
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"phoneNumber"}
                    id={"phoneNumber"}
                    selectedValue={
                      companiesDetail && companiesDetail.contactPhoneNumber
                    }
                    placeholder={"Contact Phone Number"}
                    setValue={(e) => setPhoneNumber(e.target.value)}
                  ></InputComponent>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Email
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"email"}
                    id={"email"}
                    selectedValue={companiesDetail && companiesDetail.email}
                    placeholder={"Email"}
                    setValue={(e) => setEmail(e.target.value)}
                  ></InputComponent>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Website
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"website"}
                    id={"website"}
                    selectedValue={companiesDetail && companiesDetail.website}
                    placeholder={"Website"}
                    setValue={(e) => setWebsite(e.target.value)}
                  ></InputComponent>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Status
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SwitchComponent
                    checked={companiesDetail?.status == true ? true : false}
                    childToParent={(e) => {
                      setCheckedData(e);
                      getDynamicSwitchData(e);
                    }}
                  ></SwitchComponent>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Photo
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <PhotoUpload
                    isMultiple={true}
                    name={"photo"}
                    id={"photo"}
                    type={"file"}
                    value={(e) => getImageData(e)}
                  />

                  <div className="mt-2 grid grid-cols-2 gap-2 justify-center">
                    {photoLink ? (
                      [...photoLink, ...newPhoto].map((file, index) => (
                        <div key={index} className="flex flex-col items-center">
                          <img
                            style={{
                              height: 200,
                              width: 200,
                              margin: 10,
                              marginBottom: 15,
                            }}
                            src={file}
                          />
                          <button
                            onClick={() => deleteFile(index)}
                            type="button"
                            className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Delete
                          </button>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Company Countries
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SelectComponent
                    inputId={"companyCountries"}
                    name={"companyCountries"}
                    array={country}
                    value={(e) => setCompanyCountries(e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Product Countries
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SelectComponent
                    inputId={"productCountries"}
                    name={"productCountries"}
                    array={product}
                    value={(e) => setProductCompanies(e.target.value)}
                  />
                </div>
              </div> */}
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleClick}
                className={`rounded-md ${
                  isFormValid ? "bg-indigo-600" : "bg-gray-300"
                } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:${
                  isFormValid ? "bg-indigo-500" : "bg-gray-300"
                } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default EditCompany;
