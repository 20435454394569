import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USER_LOG,
  GET_USER_LOG_ERROR,
  ADD_USERS,
  ADD_USERS_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  EDIT_USERS,
  EDIT_USERS_ERROR,
} from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Auth/get-users", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_USERS_ERROR,
      payload: error,
    });
  }
};

export const getUserLog = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Auth/get-logs", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_USER_LOG,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_USER_LOG_ERROR,
      payload: error,
    });
  }
};

export const getUserLogFiltered = (data) => async (dispatch) => {
  try {
    const res = await axios.get(api + "Auth/get-logs" + data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_USER_LOG,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_USER_LOG_ERROR,
      payload: error,
    });
  }
};

export const addUser = (data) => (dispatch) =>
  axios
    .post(`${api}Auth/register`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: ADD_USERS,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: ADD_USERS_ERROR,
        payload: error,
      });
    });

export const deleteUser = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Auth/delete/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_USER,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const editUser = (data) => (dispatch) =>
  axios
    .put(`${api}Auth/update`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: EDIT_USERS,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: EDIT_USERS_ERROR,
        payload: error,
      });
    });
