export default function TextArea({
  inputLabel,
  name,
  id,
  value,
  setValue,
  selectedValue,
  ref,
  placeholder,
}) {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {inputLabel}
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          name={name}
          id={id}
          className="block px-10 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue={selectedValue}
          onChange={setValue}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
