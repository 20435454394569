import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";

export const Layout = ({ children }) => {
  return (
    <div>
      <Sidebar>{children}</Sidebar>
    </div>
  );
};
