import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import mildataLogo from "../Source/Images/mildatalogo.png";

export default function Loading() {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <Box position="relative" display="inline-flex" width={200}>
          <CircularProgress size={200} style={{ color: "#4F46E5" }} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" component="div" color="textSecondary">
              <img className="h-25 w-25" src={mildataLogo} />
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
}
