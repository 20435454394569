import {
  GET_REPORTS,
  GET_REPORTS_ERROR,
  GET_REPORTS_TYPE,
  GET_REPORTS_TYPE_ERROR,
  POST_NEW_REPORTS,
  POST_NEW_REPORTS_ERROR,
  DELETE_REPORTS,
  DELETE_REPORTS_ERROR,
} from "../types";

const initialState = {
  reports: [],
  reportTypes: [],
  postReport: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        reports: action.payload,
        loading: false,
      };

    case GET_REPORTS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_REPORTS_TYPE:
      return {
        ...state,
        reportTypes: action.payload,
        loading: false,
      };

    case GET_REPORTS_TYPE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case POST_NEW_REPORTS:
      return {
        ...state,
        postReport: action.payload,
        loading: false,
      };

    case POST_NEW_REPORTS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_REPORTS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_REPORTS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
