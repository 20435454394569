import React, { useState, useEffect } from "react";
import TextArea from "../../../Components/Textarea";
import InputComponent from "../../../Components/Input";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import SwitchComponent from "../../../Components/Switch";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getCategories,
  getSubCategories,
  getProducts,
} from "../../../Store/actions/CategoriesAction";
import {
  getUpdatesType,
  refreshUpdate,
  getUpdateDetail,
} from "../../../Store/actions/UpdatesAction";

const EditUpdate = () => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [updateType, setUpdateType] = useState("");
  const [productId, setProductId] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [sendData, setSendData] = useState([]);
  const [checkedData, setCheckedData] = useState(false);
  const [dynamicSwitchData, setDynamicSwitchData] = useState([]);
  const [categoryId, setCategoryId] = useState("");

  const categoriesListData = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoriesListData;

  const subCategoriesListData = useSelector((state) => state.subCategoryList);
  const {
    subCategoriesByCategoryLoad,
    subCategoriesByCategoryErr,
    subCategoriesByCategory,
  } = subCategoriesListData;

  const productListData = useSelector((state) => state.productList);
  const { load1, err1, products } = productListData;

  const updatesTypeList = useSelector((state) => state.updatesTypeList);
  const { loadUpdatesType, errUpdatesType, updateTypes } = updatesTypeList;

  const updateDetailList = useSelector((state) => state.updatesTypeList);
  const { loadupdateDetail, errupdateDetail, updateDetail } = updateDetailList;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    let getQueryUpdateId = query.get("id");

    dispatch(getCategories());
    dispatch(getUpdatesType());

    if (getQueryUpdateId) {
      setCategoryId(query.get("categoryId"));
      dispatch(getUpdateDetail(getQueryUpdateId));
    }
  }, []);

  useEffect(() => {
    if (
      updateDetail &&
      updateDetail[0]?.simpleProductVM?.productSubCategoryId
    ) {
      dispatch(
        getProducts(updateDetail?.[0]?.simpleProductVM?.productSubCategoryId)
      );
    }

    if (updateDetail) {
      dispatch(getSubCategories(query.get("categoryId")));
    }
  }, [updateDetail, categoryId]);

  console.log(updateDetail);

  const handleClick = () => {
    let switchKeys = Object.keys(dynamicSwitchData);
    let switchValues = Object.values(dynamicSwitchData);

    let status = "";

    let statusCheck = switchKeys.includes("status");

    status = statusCheck && switchValues[switchKeys.indexOf("status")];

    setSendData((prevSendingData) => ({
      ...prevSendingData,
      id: updateDetail[0]?.updateId,
      originalDescription:
        description == "" ? updateDetail[0]?.originalDescription : description,
      updateTypeId:
        updateType == "" ? updateDetail[0]?.updateTypeVM.id : updateType,
      productMasterId:
        productId == "" ? updateDetail[0]?.simpleProductVM.id : productId,
      status: status,
    }));

    setSuccessModal(true);
  };

  const getDataFromCategorySelect = (data) => {
    dispatch(getSubCategories(data));
  };

  const getDataFromSubCategory = (data) => {
    dispatch(getProducts(data));
  };

  const getDataFromProducts = (data) => {
    setProductId(data);
  };

  const getDataFromUpdateType = (data) => {
    setUpdateType(data);
  };

  const getDynamicSwitchData = (e) => {
    setDynamicSwitchData({
      ...dynamicSwitchData,
      ["status"]: e,
    });
  };

  const successModalButton = (e) => {
    if (e == "save") {
      console.log(sendData);
      dispatch(refreshUpdate(sendData));
      //window.location.reload(false);
      setSuccessModal(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Edit an Update
          </h2>
        </div>
        <div className="px-4 py-5 sm:p-6 ">
          <form className="mx-auto max-w-7xl sm:px-6 lg:px-8 ">
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Category
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SingleSelectComponent
                    options={categories}
                    onChange={getDataFromCategorySelect}
                    selectedValue={
                      updateDetail &&
                      updateDetail[0]?.simpleProductVM?.productMainCategoryId
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Sub Category
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SingleSelectComponent
                    options={subCategoriesByCategory}
                    onChange={getDataFromSubCategory}
                    selectedValue={
                      updateDetail &&
                      updateDetail[0]?.simpleProductVM?.productSubCategoryId
                    }
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Product
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SingleSelectComponent
                    options={products?.simpleProducts}
                    onChange={getDataFromProducts}
                    selectedValue={
                      updateDetail && updateDetail[0]?.simpleProductVM.id
                    }
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Update Type
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SingleSelectComponent
                    options={updateTypes}
                    onChange={getDataFromUpdateType}
                    selectedValue={
                      updateDetail && updateDetail[0]?.updateTypeVM.id
                    }
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Description
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <TextArea
                    name={"description"}
                    id={"description"}
                    setValue={(e) => setDescription(e.target.value)}
                    selectedValue={
                      updateDetail && updateDetail[0]?.originalDescription
                    }
                  />
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Status
              </label>
              <div className="mt-2 sm:col-span-1 sm:mt-0">
                <SwitchComponent
                  checked={checkedData == true ? true : false}
                  childToParent={(e) => {
                    setCheckedData(e);
                    getDynamicSwitchData(e);
                  }}
                ></SwitchComponent>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleClick}
                className={`rounded-md bg-indigo-600" px-3 py-2 text-sm font-semibold text-white shadow-sm hover: bg-indigo-500
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default EditUpdate;
