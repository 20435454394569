import React, { useState, useEffect } from "react";
import Loading from "./Layout/Loading";
import { Layout } from "./Layout/Layout";
import { Router } from "./Router/Router";
import Login from "./Views/Login/Login";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

export default function App() {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const decodedToken = jwtDecode(storedToken);
        const nowTime = new Date().getTime();

        if (decodedToken.exp * 1000 < nowTime) {
          localStorage.removeItem("token");
          setToken("");
        } else {
          setToken(storedToken);
        }
      } else {
        setToken("");
      }
    } catch (e) {
      localStorage.removeItem("token");
      setToken("");
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className="justify-center align-middle items-center">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <div>
      {token !== "" ? (
        <Layout>
          <Router />
        </Layout>
      ) : (
        <Login />
      )}
    </div>
  );
}
