import React, { useState, useEffect } from "react";
import EditorComponent from "../../../Components/EditorJsConfig/Editor";
import InputComponent from "../../../Components/Input";
import SelectComponent from "../../../Components/Selectbox";
import PhotoUpload from "../../../Components/PhotoUpload";
import SingleSelectComponent from "../../../Components/SingleSelectbox";
import SuccessAlert from "../../../Components/SuccessAlertModal";
import TextArea from "../../../Components/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getSubCategoryDetail,
  updateSubCategory,
} from "../../../Store/actions/CategoriesAction";
import { postProductFile } from "../../../Store/actions/ProductAction";
import { useLocation } from "react-router-dom";
import { CategorySharp } from "@mui/icons-material";

const AddSubCategories = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState([]);
  const [newPhoto, setNewPhoto] = useState([]);
  const [photoLink, setPhotoLink] = useState([]);
  const [editorContent, setEditorContent] = useState([]);
  const [categorySelectData, setCategorySelectData] = useState("");
  const [sendData, setSendData] = useState([]);
  const [images1, setImages1] = useState([]);
  const [categoryId, setCategoryId] = useState([]);

  const [successModal, setSuccessModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const categoriesListData = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoriesListData;

  const subCategoryDetailList = useSelector((state) => state.categoryList);
  const { subCategoryDetailLoad, subCategoryDetailErr, subCategoryDetail } =
    subCategoryDetailList;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let getQueryId = query.get("id");
  let getQueryCategoryId = query.get("categoryId");

  useEffect(() => {
    if (!categories.length) {
      dispatch(getCategories());
    }
    if (getQueryId) {
      dispatch(getSubCategoryDetail(getQueryId));
    }
  }, [dispatch, getQueryId, categories.length]);

  useEffect(() => {
    setPhotoLink((prevData) =>
      subCategoryDetail && subCategoryDetail.images1
        ? subCategoryDetail.images1.map((item) => item.url)
        : []
    );
  }, [subCategoryDetail]);

  const getDataFromEditor = (data) => {
    setEditorContent(data);
  };

  const getDataFromCategorySelect = (data) => {
    setCategorySelectData(data);
  };

  const handleClick = async () => {
    const formDataArray = [];
    const photoDataArray = [];

    for (let getFile of photo) {
      const formData = new FormData();
      const file = getFile;
      const fileName = getFile.name;

      formData.append("file", file);
      formData.append("fileName", fileName);
      formDataArray.push({ formData, fileName });
    }

    await Promise.all(
      formDataArray.map(({ formData }) => dispatch(postProductFile(formData)))
    );

    formDataArray.forEach(({ fileName }) => {
      photoDataArray.push(fileName);
    });

    setSendData((prevSendingData) => ({
      ...prevSendingData,
      id: subCategoryDetail.id,
      productMainCategoryId: subCategoryDetail.productMainCategoryId,
      status: true,
      originalName: !title ? subCategoryDetail.originalName : title,
      info: !description ? subCategoryDetail.info : description,
      originalDoctrineText: editorContent
        ? editorContent
        : subCategoryDetail.originalDoctrineText,
      images1: [
        ...photoLink.map((x) => ({
          url: x,
        })),
        ...photoDataArray.map((fileName) => ({
          url: "https://cdn.mildata.net/data/" + fileName,
        })),
      ],
    }));

    setSuccessModal(true);
  };

  const successModalButton = (e) => {
    if (e == "save") {
      setSuccessModal(false);
      //console.log(sendData);
      dispatch(updateSubCategory(sendData));
      window.location.reload(false);
    } else if (e == "cancel") {
      setSuccessModal(false);
    }
  };

  const getImageData = async (data) => {
    if (data.target.files != null) {
      const fileList = data.target.files;
      const fileArray = Array.from(fileList);

      setPhoto((prevPhotos) => [...prevPhotos, ...fileArray]);

      setNewPhoto((prevPhotos) => [
        ...prevPhotos,
        ...fileArray.map((file) => URL.createObjectURL(file)),
      ]);
    }
  };

  const deleteFile = (e) => {
    const updatedImages = [...photoLink, ...newPhoto];
    updatedImages.splice(e, 1);
    setPhotoLink(updatedImages);
    setNewPhoto([]);
  };

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow border border-gray-900/25">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Edit Sub Category
          </h2>
        </div>
        <div className="px-4 py-5 sm:p-6 ">
          <form className="mx-auto max-w-7xl sm:px-6 lg:px-7">
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Category
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <SingleSelectComponent
                    options={categories}
                    onChange={getDataFromCategorySelect}
                    selectedValue={
                      subCategoryDetail &&
                      parseInt(subCategoryDetail.productMainCategoryId)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Title
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <InputComponent
                    type={"text"}
                    name={"title"}
                    id={"title"}
                    selectedValue={
                      subCategoryDetail ? subCategoryDetail.originalName : ""
                    }
                    placeholder={"Title"}
                    setValue={(e) => setTitle(e.target.value)}
                  ></InputComponent>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Info
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <TextArea
                    key={"description"}
                    type={"text"}
                    name={"description"}
                    id={"description"}
                    selectedValue={
                      subCategoryDetail ? subCategoryDetail.info : ""
                    }
                    placeholder={"Info"}
                    setValue={(e) => setDescription(e.target.value)}
                  ></TextArea>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Editor
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <EditorComponent
                    selectedValue={
                      subCategoryDetail
                        ? subCategoryDetail.originalDoctrineText
                        : []
                    }
                    childToParent={getDataFromEditor}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                  Photo
                </label>
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                  <PhotoUpload
                    isMultiple={true}
                    name={"photo"}
                    id={"photo"}
                    type={"file"}
                    value={(e) => getImageData(e)}
                  />

                  <div className="mt-2 grid grid-cols-2 gap-2 justify-center">
                    {photoLink ? (
                      [...photoLink, ...newPhoto].map((file, index) => (
                        <div key={index} className="flex flex-col items-center">
                          <img
                            style={{
                              height: 200,
                              width: 200,
                              margin: 10,
                              marginBottom: 15,
                            }}
                            src={file}
                          />
                          <button
                            onClick={() => deleteFile(index)}
                            type="button"
                            className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Delete
                          </button>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleClick}
                className={`rounded-md  bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover: bg-indigo-500
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      {successModal == true && (
        <div>
          <SuccessAlert
            mainTitle={" Data is ready to save"}
            text={"Are you sure you want to save?"}
            visible={successModal}
            childToParent={successModalButton}
          ></SuccessAlert>
        </div>
      )}
    </div>
  );
};

export default AddSubCategories;
