import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USER_LOG,
  GET_USER_LOG_ERROR,
  ADD_USERS,
  ADD_USERS_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  EDIT_USERS,
  EDIT_USERS_ERROR,
} from "../types";

const initialState = {
  loading: true,
  users: [],
  userLog: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };

    case GET_USERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_USER_LOG:
      return {
        ...state,
        userLog: action.payload,
        loading: false,
      };

    case GET_USER_LOG_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case ADD_USERS:
      return {
        ...state,
        loading: false,
      };

    case ADD_USERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case EDIT_USERS:
      return {
        ...state,
        loading: false,
      };

    case EDIT_USERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_USER:
      return {
        ...state,
        loading: false,
      };

    case DELETE_USER_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
