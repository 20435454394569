import { combineReducers } from "redux";
import CountryReducer from "./CountryReducer";
import CategoriesReducer from "./CategoriesReducer";
import ReportsReducer from "./ReportsReducer";
import CompanyReducer from "./CompanyReducer";
import ProductReducer from "./ProductReducer";
import UpdatesReducer from "./UpdatesReducer";
import AmmunutionReducer from "./AmmunutionReducer";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";

export default combineReducers({
  countriesList: CountryReducer,
  categoryList: CategoriesReducer,
  reportsList: ReportsReducer,
  reportTypesList: ReportsReducer,
  companyList: CompanyReducer,
  companyDetailList: CompanyReducer,
  subCategoryList: CategoriesReducer,
  subCategoryProps: CategoriesReducer,
  productList: CategoriesReducer,
  productDetailList: CategoriesReducer,
  subCategorySelectboxDataList: CategoriesReducer,
  updatesTypeList: UpdatesReducer,
  ammunitionList: AmmunutionReducer,
  login: LoginReducer,
  user: UserReducer,
});
