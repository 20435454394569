import {
  GET_REPORTS,
  GET_REPORTS_ERROR,
  POST_NEW_REPORTS,
  POST_NEW_REPORTS_ERROR,
  GET_REPORTS_TYPE,
  GET_REPORTS_TYPE_ERROR,
  DELETE_REPORTS,
  DELETE_REPORTS_ERROR,
  UPDATE_REPORTS,
  UPDATE_REPORTS_ERROR,
} from "../types";
import axios from "axios";
import { api, token } from "../Enviroment";

export const getReports = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Report/reports", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_REPORTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_REPORTS_ERROR,
      payload: error,
    });
  }
};

export const addReport = (data) => (dispatch) =>
  axios
    .post(`${api}Report/admin/add-report`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: POST_NEW_REPORTS,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: POST_NEW_REPORTS_ERROR,
        payload: error,
      });
    });

export const getReportTypes = () => async (dispatch) => {
  try {
    const res = await axios.get(api + "Report/report-types", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_REPORTS_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_REPORTS_TYPE_ERROR,
      payload: error,
    });
  }
};

export const deleteReport = (data) => {
  return (dispatch) => {
    axios
      .delete(api + "Report/admin/remove-report/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch({
          type: DELETE_REPORTS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_REPORTS_ERROR,
          payload: error,
        });
      });
  };
};

export const updateReport = (data) => (dispatch) =>
  axios
    .put(`${api}Report/admin/update-report`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) =>
      dispatch({
        type: UPDATE_REPORTS,
        payload: response.data,
      })
    )
    .catch((error) => {
      console.error(error); // Log the error to the console
      dispatch({
        type: UPDATE_REPORTS_ERROR,
        payload: error,
      });
    });
