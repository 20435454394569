import {
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_DETAIL,
  GET_COUNTRIES_DETAIL_ERROR,
  GET_COUNTRY_PROPS,
  GET_COUNTRY_PROPS_ERROR,
  GET_CONTINENTS,
  GET_CONTINENTS_ERROR,
  GET_FORM_OF_GOVERMENT,
  GET_FORM_OF_GOVERMENT_ERROR,
  GET_REGIONS,
  GET_REGIONS_ERROR,
  GET_ORGANIZATION,
  GET_ORGANIZATION_ERROR,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_ERROR,
} from "../types";

const initialState = {
  countries: [],
  countryDetail: [],
  countryProps: [],
  continents: [],
  formOfGoverment: [],
  regions: [],
  organizations: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        loading: false,
      };

    case GET_COUNTRIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_REGIONS:
      return {
        ...state,
        regions: action.payload,
        loading: false,
      };

    case GET_REGIONS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_ORGANIZATION:
      return {
        ...state,
        organizations: action.payload,
        loading: false,
      };

    case GET_ORGANIZATION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_COUNTRIES_DETAIL:
      return {
        ...state,
        countryDetail: action.payload,
        loading: false,
      };

    case GET_COUNTRIES_DETAIL_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_COUNTRY_PROPS:
      return {
        ...state,
        countryProps: action.payload,
        loading: false,
      };

    case GET_COUNTRY_PROPS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_CONTINENTS:
      return {
        ...state,
        continents: action.payload,
        loading: false,
      };

    case GET_CONTINENTS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_FORM_OF_GOVERMENT:
      return {
        ...state,
        formOfGoverment: action.payload,
        loading: false,
      };

    case GET_FORM_OF_GOVERMENT_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    case UPDATE_COUNTRY:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_COUNTRY_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
